import { makeStyles } from '@material-ui/core/styles';
import logo from '@resources/icons/svg/logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '25.6rem',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    boxShadow: '6px 0px 18px rgba(0, 0, 0, 0.06)',
    cursor: 'pointer',
  },
  img: {
    display: 'block',
  },
  span: {
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: '2.7rem',
    color: theme.palette.common.grey,
  },
}));

const Logo = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={ classes.root } onClick={ onClick }>
      <img className={ classes.img } src={ logo } alt="logo" />
      <span className={ classes.span }>ADMIN</span>
    </div>
  );
};

export default Logo;
