// eslint-disable-next-line import/prefer-default-export
export const ROUTES = {
  HOME: '/',
  PROFILE: '/profile',
  LOGIN: '/login',
  STATISTICS: '/statistics',
  STATISTICS_LIMITS: '/limits',
  REGISTER: '/register',
  INTERIORS: '/interiors',
  MODELS: '/models',
  SCENES: {
    ROOT: '/scenes',
    NEW: '/create/new',
    EDIT: (id = ':id') => `/${id}`,
  },
  COMPANIES: '/companies',
  USERS: '/users',
  PRODUCTS: {
    ROOT: '/products',
  },
  MATERIALS: '/materials',
  VIZUALIZATION_SETTINGS: '/visualizations',
  RELIGHT_SETTINGS: '/relight',
  ATTRIBUTE_DEFINITIONS: '/attributeDefinitions',
  STYLING: '/styling',
};
