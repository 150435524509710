import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button from '@shared/components/button';
import { SketchPicker } from 'react-color';
import GroupField from '@shared/views/form-dialog/GroupField';
import Grid from '@material-ui/core/Grid';
import ChevronDownIcon from '@resources/icons/ChevronDownIcon';
import { defaultTheme } from '@shared/constants';
import StylingPreview from '@pages/styling/modal/StylingPreview';
import classNames from 'classnames';
import FontTextField from '@pages/styling/modal/FontTextField';
import { actions as appActions } from '@store/app';
import OutsideClickAwaredDiv from '@shared/components/basic/OutsideClickAwaredDiv';
import { createStyling, updateStyling } from '@store/styling/actions';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    gap: '107px',
  },
  formLeft: {
    display: 'flex',
    width: '300px',
    flexDirection: 'column',
    gap: '8px',
    flexShrink: 0,
  },
  colorCell: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '1px dashed gray',
    position: 'relative',
    boxSizing: 'content-box',
  },
  activeColorCell: {
    '&::after': {
      content: '""',
      display: 'block',
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      margin: '-6px',
      border: '2px solid #00C98F',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  colorPickerWrapper: {
    width: 0,
    position: 'absolute',
    left: '216px',
    top: '93px',
    zIndex: 1000,
  },
  fontFieldWrapper: {
    marginTop: '8px',
  },
  title: {
    fontSize: '2rem',
  },
  content: {
    width: '960px',
    minHeight: '300px',
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  labelIcon: {
    transform: 'rotate(-90deg)',
  },
}));

const colorPickerPresetColors = [];
const availableFontOptions = [
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Roboto', label: 'Roboto (Cyrillic supported)' },
  { value: 'Lato', label: 'Lato' },
  { value: 'Prompt', label: 'Prompt' },
  { value: 'Open Sans', label: 'Open Sans (Cyrillic supported)' },
  { value: 'Montserrat', label: 'Montserrat (Cyrillic supported)'},
];

const StylingModal = ({ open, onClose, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getDefaultValues = useCallback(() => ({
    ...defaultTheme,
    fontFamily: defaultTheme.fontFamily.split(',')[0],
    ...data,
  }), [data]);

  const form = useForm({
    defaultValues: getDefaultValues(),
  });
  const { control, formState: { errors: fieldsErrors }, handleSubmit, reset } = form;

  useEffect(() => {
    if (open) {
      reset(getDefaultValues());
    }
  }, [open, reset, getDefaultValues]);

  const [showPickerFor, setShowPickerFor] = useState(null);

  const onSubmit = useCallback((formData) => {
    onClose();

    try {
      if (!data) {
        dispatch(createStyling(formData));
      } else {
        dispatch(updateStyling(formData));
      }
    } catch (error) {
      console.error('Error on styling save', error);
      dispatch(appActions.addMessage({ message: 'Unexpected error during save operation.', type: 'error' }));
    }
  }, [dispatch, onClose, data]);

  const handleColorCellClick = useCallback((name) => setShowPickerFor((prevState) => {
    if (prevState === name) {
      return null;
    }

    return name;
  }), []);

  const handleColorPickerOutsideClick = useCallback((name) => setShowPickerFor((prevState) => {
    if (prevState === name) {
      return null;
    }

    return prevState;
  }), []);

  const renderColorField = useCallback(({ field }) => {
    const changeHandler = (value) => { field.onChange(value.hex); };

    return (
      <>
        <div
          style={ { backgroundColor: field.value } }
          className={ classNames(classes.colorCell, { [classes.activeColorCell]: (showPickerFor === field.name) }) }
          onClick={ handleColorCellClick.bind(this, field.name) }
        />

        {showPickerFor === field.name ? (
          <OutsideClickAwaredDiv
            className={ classes.colorPickerWrapper }
            onClickOutside={ handleColorPickerOutsideClick.bind(this, field.name) }
          >
            <SketchPicker
              presetColors={ colorPickerPresetColors }
              disableAlpha
              color={ field.value }
              onChange={ changeHandler }
            />
          </OutsideClickAwaredDiv>
        ) : null}
      </>
    );
  }, [showPickerFor, handleColorCellClick, handleColorPickerOutsideClick, classes]);

  return (
    <Dialog open={ open } onClose={ onClose } maxWidth="md">
      <DialogTitle className={ classes.title } disableTypography>
        {data ? 'Edit' : 'Create'} Styling
      </DialogTitle>

      <DialogContent className={ classes.content }>
        <form onSubmit={ handleSubmit(onSubmit) } className={ classes.form }>
          <FormProvider { ...form }>
            <div className={ classes.formLeft }>
              <Grid container>
                <Grid item xs={ 4 }>
                  <span>Base colors</span>
                </Grid>

                <Grid item xs={ 2 }>
                  <Grid container justifyContent="center">
                    <ChevronDownIcon className={ classes.labelIcon } />
                  </Grid>
                </Grid>

                <Grid item xs={ 6 }>
                  <span>Hover colors</span>
                </Grid>
              </Grid>

              <Grid container>
                <GroupField>
                  <Controller
                    name="color1"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />

                  <Controller
                    name="color1Hover"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />
                </GroupField>
              </Grid>

              <Grid container>
                <GroupField>
                  <Controller
                    name="color2"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />

                  <Controller
                    name="color2Hover"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />
                </GroupField>
              </Grid>

              <Grid container>
                <GroupField>
                  <Controller
                    name="color3"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />

                  <Controller
                    name="color3Hover"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />
                </GroupField>
              </Grid>

              <Grid container>
                <GroupField>
                  <Controller
                    name="color4"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />

                  <Controller
                    name="color4Hover"
                    control={ control }
                    rules={ { required: 'Required' } }
                    render={ renderColorField }
                  />
                </GroupField>
              </Grid>

              <div className={ classes.fontFieldWrapper }>
                <Controller
                  name="fontFamily"
                  control={ control }
                  rules={ { required: 'Required' } }
                  render={ ({ field }) => (
                    <FontTextField
                      { ...field }
                      label="Font"
                      options={ availableFontOptions }
                      error={ fieldsErrors.fontFamily }
                    />
                  ) }
                />
              </div>
            </div>

            <StylingPreview />
          </FormProvider>
        </form>

      </DialogContent>
      <DialogActions className={ classes.actions }>
        <Button color="secondary" variant="outlined" onClick={ onClose }>Cancel</Button>
        <Button onClick={ handleSubmit(onSubmit) }>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StylingModal;
