import { useCallback } from 'react';
import CreateVariantsConfigurationForm from './VariantsConfigurationForm';
import VariantsConfigurationFormFieldset from '../VariantsConfigurationFormFieldset';
import ProductFormDialog from '../ProductFormDialog';
import { useDispatch } from 'react-redux';
import { resetProductApiState } from '@store/products/actions';

const VariantsConfigurationFormDialog = ({
  open,
  productData,
  onAddMaterialPreset,
  onEditMaterialPreset,
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (formData, close = true) => {
    if (close) {
      dispatch(resetProductApiState());
      onClose();
    }
  }, [dispatch, onClose]);

  const handleCancel = useCallback(() => {
    dispatch(resetProductApiState());
    onClose();
  }, [dispatch, onClose]);

  return (
    <ProductFormDialog
      open={ open }
      Form={ CreateVariantsConfigurationForm }
      FormProps={ {
        open,
        productData,
        onSubmit: handleSubmit,
      } }
      title="Configure Variations"
      content={ (
        <VariantsConfigurationFormFieldset
          onAddMaterialPreset={ onAddMaterialPreset }
          onEditMaterialPreset={ onEditMaterialPreset }
        />
      ) }
      onCancel={ handleCancel }
      onClose={ onClose }
    />
  );
};

export default VariantsConfigurationFormDialog;
