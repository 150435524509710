export const LOAD_MATERIAL_PRESET_LIST = 'LOAD_MATERIAL_PRESET_LIST';
export const RENDER_MATERIAL_PRESET_LIST = 'RENDER_MATERIAL_PRESET_LIST';
export const LOAD_MATERIAL_ATTRIBUTE_DEFINITION_LIST = 'LOAD_MATERIAL_PRESET_LIST';
export const RENDER_MATERIAL_ATTRIBUTE_DEFINITION_LIST = 'RENDER_MATERIAL_ATTRIBUTE_DEFINITION_LIST';
export const CREATE_MATERIAL_PRESET = 'CREATE_MATERIAL_PRESET';
export const CREATE_MATERIAL_PRESET_SUCCESS = 'CREATE_MATERIAL_PRESET_SUCCESS';
export const CREATE_MATERIAL_PRESET_ERROR = 'CREATE_MATERIAL_PRESET_ERROR';
export const UPDATE_MATERIAL_PRESET = 'UPDATE_MATERIAL_PRESET';
export const UPDATE_MATERIAL_PRESET_SUCCESS = 'UPDATE_MATERIAL_PRESET_SUCCESS';
export const UPDATE_MATERIAL_PRESET_ERROR = 'UPDATE_MATERIAL_PRESET_ERROR';
export const DELETE_MATERIAL_PRESET = 'DELETE_MATERIAL_PRESET';
export const DELETE_MATERIAL_PRESET_ERROR = 'DELETE_MATERIAL_PRESET_ERROR';
export const RESET_MATERIAL_PRESET_API_STATE = 'RESET_MATERIAL_PRESET_API_STATE';
export const SET_FRESH_MATERIAL_PRESET = 'SET_FRESH_MATERIAL_PRESET';

export function loadMaterialPresetList() {
  return {
    type: LOAD_MATERIAL_PRESET_LIST,
  };
}

export function renderMaterialPresetList(materialPresets) {
  return {
    type: RENDER_MATERIAL_PRESET_LIST,
    materialPresets,
  };
}

export function loadMaterialAttributeDefinitionList() {
  return {
    type: LOAD_MATERIAL_ATTRIBUTE_DEFINITION_LIST,
  };
}

export function renderMaterialAttributeDefinitionList(attributeDefinitions) {
  return {
    type: RENDER_MATERIAL_ATTRIBUTE_DEFINITION_LIST,
    attributeDefinitions,
  };
}

export function createMaterialPreset(materialPreset, setError) {
  return {
    type: CREATE_MATERIAL_PRESET,
    materialPreset,
    setError,
  };
}

export function createMaterialPresetError(error) {
  return {
    type: CREATE_MATERIAL_PRESET_ERROR,
    error,
  };
}

export function createMaterialPresetSuccess(data) {
  return {
    type: CREATE_MATERIAL_PRESET_SUCCESS,
    data,
  };
}

export function updateMaterialPreset(materialPreset, setError) {
  return {
    type: UPDATE_MATERIAL_PRESET,
    materialPreset,
    setError,
  };
}

export function updateMaterialPresetError(error) {
  return {
    type: UPDATE_MATERIAL_PRESET_ERROR,
    error,
  };
}

export function updateMaterialPresetSuccess() {
  return {
    type: UPDATE_MATERIAL_PRESET_SUCCESS,
  };
}

export function deleteMaterialPreset(id) {
  return {
    type: DELETE_MATERIAL_PRESET,
    id,
  };
}

export function deleteMaterialPresetError(id, error) {
  return {
    type: DELETE_MATERIAL_PRESET_ERROR,
    id,
    error,
  };
}

export function resetMaterialPresetApiState() {
  return {
    type: RESET_MATERIAL_PRESET_API_STATE,
  };
}

export function setFreshMaterialPreset(data) {
  return {
    type: SET_FRESH_MATERIAL_PRESET,
    data,
  };
}
