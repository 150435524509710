import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import {
  TextField,
  Fields,
  Field,
} from '@shared/views/form-dialog';
import { Fieldset, InputLabelProps } from './fieldset';
import { makeStyles } from '@material-ui/core/styles';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import AttributeDefinitionsCustomList from '@pages/products/modal/AttributeDefinitionsCustomList';
import Switch from '@shared/components/switch';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    borderTop: '1px solid #E2EAF3',
    borderBottom: '1px solid #E2EAF3',
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },
  leftSide: {
    padding: '20px 15px',
    overflow: 'auto',
    flex: '1 1 auto',
    maxWidth: '385px',
  },
  rightSide: {
    width: '100%',
    padding: '27px 20px',
    borderLeft: '1px solid #E2EAF3',
  },
  attributesTable: {
    padding: 0,
  },
  joinFieldLabel: {
    color: '#192A3E',
    marginRight: '20px',
  },
  joinValue: {
    margin: '0 5px',
    color: '#BBC4D0',

    '&.selected': {
      color: '#00C98F',
    },
  },
}));

const MaterialPresetFormFieldset = ({
  attributeDefinitions,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors: fieldsErrors },
  } = useFormContext();

  const selectedAttributes = useWatch({ control, name: Fieldset.Attributes });

  const selectedAttributeDefinitions = useMemo(() => {
    const result = [];

    attributeDefinitions.forEach((definition) => {
      const attributes = selectedAttributes.filter((attribute) => (attribute.definition === definition.id));

      if (attributes.length) {
        result.push({
          ...definition,
          attributes,
        });
      }
    });

    return result;
  }, [selectedAttributes, attributeDefinitions]);

  return (
    <Box className={ classes.root }>
      <div
        className={ classes.leftSide }
      >
        <Fields>
          <Field>
            <Controller
              name={ Fieldset.Name }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.Name]?.message }
                  label="Name"
                  required
                  error={ !!fieldsErrors[Fieldset.Name] }
                  InputLabelProps={ InputLabelProps }
                />
              ) }
            />
          </Field>

          <Field container alignItems="center">
            <span className={ classes.joinFieldLabel }>Join condition:</span>

            <Controller
              name={ Fieldset.IsIntersection }
              control={ control }
              render={ ({ field }) => (
                <>
                  <span className={ classNames(classes.joinValue, { selected: !field.value }) }>OR</span>
                  <Switch
                    { ...field }
                    checked={ field.value }
                    InputLabelProps={ InputLabelProps }
                  />
                  <span className={ classNames(classes.joinValue, { selected: field.value }) }>AND</span>
                </>
              ) }
            />
          </Field>

          <Field>
            <Controller
              name={ Fieldset.Attributes }
              control={ control }
              render={ ({ field }) => (
                <AttributesTable
                  { ...field }
                  attributeDefinitions={ attributeDefinitions }
                  className={ classes.attributesTable }
                />
              ) }
            />
          </Field>
        </Fields>
      </div>

      <div className={ classes.rightSide }>
        <AttributeDefinitionsCustomList attributeDefinitions={ selectedAttributeDefinitions } />
      </div>
    </Box>
  );
};

export default MaterialPresetFormFieldset;
