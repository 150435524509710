import * as React from 'react';

const LimitsIcon = (props) => (
  <svg
    width={ 22 }
    height={ 22 }
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <g clipPath="url(#a)" fill="#BCC8D9">
      <path d="M14.691 20h-7.38A5.316
       5.316 0 0 1 2 14.691v-7.38A5.316 5.316
        0 0 1 7.31 2h7.381A5.316 5.316 0 0 1 20
         7.31v7.381A5.316 5.316 0 0 1 14.691
          20M7.311 3.454A3.86 3.86 0 0 0 3.453
           7.31v7.381a3.86 3.86 0 0 0 3.856 3.855h7.381a3.86 3.86 0 0 0 3.855-3.855v-7.38a3.86 3.86 0 0 0-3.855-3.857z"
      />
      <path d="M15.127 6.87a5.836 5.836
       0 0 0-9.963 4.116.742.742
        0 0 0 1.485 0A4.35 4.35 0 0 1 11 6.646a4.35 4.35 0 0 1 4.351 4.34.742.742 0 0 0 1.485 0 5.78 5.78 0 0 0-1.71-4.117"
      />
      <path d="M13.777 9.088a.743.743
       0 0 0-.997.33l-1.5 2.978a2.228
        2.228 0 1 0 1.328.663l1.499-2.978a.74.74 0 0 0-.33-.993m-2.11 5.854a.747.747 0 1 1-1.335-.67.747.747 0 0 1 1.335.67"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M2 2h18v18H2z" />
      </clipPath>
    </defs>
  </svg>
);
export default LimitsIcon;
