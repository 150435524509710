import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createMaterialPreset } from '@store/material-presets/actions';
import { Form } from '@shared/views/form-dialog';
import { useEffect } from 'react';
import { selectCurrentCompany } from '@store/app/selectors';

const MaterialPresetForm = ({
  children,
  className,
  open,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.materialPresets.state);
  const company = useSelector(selectCurrentCompany);

  const form = useForm({
    defaultValues: {
      name: '',
      isIntersection: true,
      attributes: [],
    },
  });

  const handleSubmit = async (formData) => {
    onSubmit(formData, false);

    dispatch(createMaterialPreset({
      ...formData,
      attributes: formData.attributes.map((item) => item.id),
      company: company.id,
    }, form.setError));
  };

  useEffect(() => {
    if (apiState === 'success') {
      onSubmit(null, true);
    }
  }, [dispatch, apiState, onSubmit]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default MaterialPresetForm;
