import * as React from 'react';

const AttributeDefinitionsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <g clipPath="url(#clip0_1452_11021)">
      <g fill="#BCC8D9" clipPath="url(#clip1_1452_11021)">
        <path d="M19.022 16.283h-4.109V3.957c0-.971-.79-1.761-1.76-1.761H3.76C2.79 2.196
         2 2.986 2 3.956V16.87a2.94
          2.94 0 0 0 2.935 2.934h11.739a2.94 2.94
          0 0 0 2.935-2.934.587.587 0 0 0-.587-.587M4.935 18.63c-.971
           0-1.761-.79-1.761-1.761V3.957c0-.324.263-.587.587-.587h9.391c.324
            0 .587.263.587.587V16.87c0 .66.22 1.27.589 1.76zm11.739 0c-.765 0-1.418-.491-1.66-1.175h3.32a1.76 1.76 0 0 1-1.66 1.175"
        />
        <path d="M11.978 4.543H4.935a.587.587
         0 0 0 0 1.174h7.043a.587.587
          0 0 0 0-1.174M11.978 8.065H4.935a.587.587
           0 0 0 0 1.174h7.043a.587.587 0 0 0 0-1.174M11.978
            11.587H4.935a.587.587 0 0 0 0 1.174h7.043a.587.587
             0 0 0 0-1.174M11.978 15.109H4.935a.587.587
              0 0 0 0 1.174h7.043a.587.587
               0 0 0 0-1.174M18.63 2.196h-1.174c-.756 0-1.37.614-1.37
                1.37v8.608c0 .084.021.181.062.262l1.37 2.74a.587.587
                 0 0 0 1.05 0l1.37-2.74a.6.6 0 0 0 .061-.262V3.565c0-.755-.614-1.37-1.37-1.37M18.042
                  13.6l-.42-.84h.84zm.782-2.014H17.26v-5.87h1.565zm0-7.043H17.26v-.979c0-.108.088-.195.195-.195h1.174c.108 0 .196.087.196.195z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1452_11021">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
      <clipPath id="clip1_1452_11021">
        <path fill="#fff" d="M2 2h18v18H2z" />
      </clipPath>
    </defs>
  </svg>
);

export default AttributeDefinitionsIcon;


