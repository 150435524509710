import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';
import DeleteIcon from '@resources/icons/DeleteIcon';
import {
  deleteMaterialError,
} from '@store/materials/actions';
import DialogsContainer from '@shared/components/card-controls/DialogsContainer';
import WarningDialog from '@shared/components/card-controls/WarningDialog';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import PlusIcon from '@resources/icons/PlusIcon';
import EditIcon from '@resources/icons/EditIcon';
import {deleteMaterialPreset} from '@store/material-presets/actions';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import useAlertControl, {Types} from '@shared/components/card-controls/useAlertControl';

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& .loader': {
        order: 3,
        margin: '0 3px',
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  muiOption: {
    padding: '10px 8px 10px 12px',
    borderBottom: '1px solid #E2EAF3',

    '&:nth-last-child(-n+2)': {
      borderBottom: 'none',
    },
  },
  option: {
    width: '100%',
    height: '20px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionLabel: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.common.darkGrey,
  },
  optionActionsWrapper: {
    display: 'flex',
    gap: '10px',
  },
  smallIconButton: {
    padding: 0,

    '& svg': {
      fontSize: '15px',
    },
  },
  createNewPresetBtnPlaceholder: {
    height: '16px',
    width: '100%',
  },
  createNewPresetBtn: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    backgroundColor: theme.palette.common.green,
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.common.white,
    gap: '10px',
  },
  createNewPresetIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    color: theme.palette.common.green,
    backgroundColor: theme.palette.common.white,
  },
}));

const ADD_NEW_BTN_OPTION = 'ADD_NEW_BTN_OPTION';

const MaterialPresetTextField = forwardRef(({
  label,
  onChange,
  value,
  options,
  disabled,
  onAddMaterialPreset,
  onEditMaterialPreset,
}, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [
    alertOpen,
    { handleAlertControlClick, handleAgree, handleDisagree, ariaAction, title },
  ] = useAlertControl();

  const defaultValue = { name: '' };
  const presetOptions = useMemo(() => [...options, ADD_NEW_BTN_OPTION], [options]);

  useEffect(() => {
    const matchedValueOption = value?.id ? presetOptions.find((item) => item.id === value.id) : null;

    if (value?.id) {
      if (!matchedValueOption) {
        onChange(defaultValue);
      } else if (matchedValueOption && value.name !== matchedValueOption.name) {
        onChange(matchedValueOption);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetOptions]);

  const handleChange = useCallback((_, data) => {
    if ((data && data.id) || !data) {
      onChange(data || defaultValue);
    } else {
      const selected = options.find((op) => op.name === data) || (value?.name === data ? value : defaultValue);
      onChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, options, value]);

  const getOptionObject = useCallback((option) => {
    let result = {};

    result = ((typeof option === 'string' || option instanceof String)
      ? options.find((op) => op.name === option)
      : option
    ) || {};

    return result;
  }, [options]);

  const getOptionLabel = useCallback((option) => (
    option ? (getOptionObject(option).name || '') : option
  ), [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => (
    value ? option.id === getOptionObject(value).id : value
  ), [getOptionObject]);

  const handleEdit = useCallback((materialPreset, event) => {
    event.stopPropagation();
    onEditMaterialPreset(materialPreset);
  }, [onEditMaterialPreset]);

  const handleDelete = useCallback((materialPreset, event) => {
    event.stopPropagation();
    handleAlertControlClick(Types.Delete, () => dispatch(deleteMaterialPreset(materialPreset.id)))();
  }, [handleAlertControlClick, dispatch]);

  const handleBlur = useCallback((e) => {
    if (value?.name !== e.target.value) {
      handleChange(e, e.target.value);
    }
  }, [value, handleChange]);

  const handleAddMaterialPreset = useCallback((event) => {
    event.stopPropagation();
    onAddMaterialPreset();
  }, [onAddMaterialPreset]);

  const renderPresetOption = useCallback((option, { selected }) => {
    let result = null;

    if (option === ADD_NEW_BTN_OPTION) {
      result = (
        <>
          <div className={ classes.createNewPresetBtnPlaceholder } />
          <div className={ classNames(classes.createNewPresetBtn) } onClick={ handleAddMaterialPreset }>
            <div className={ classes.createNewPresetIconWrapper }>
              <PlusIcon />
            </div>
            Create New
          </div>
        </>
      );
    } else {
      result = (
        <div className={ classNames({ selected }, classes.option) }>
          <div className={ classes.optionLabel }>
            { option.name }
          </div>

          <div className={ classes.optionActionsWrapper }>
            <IconButton
              classes={ { sizeSmall: classes.smallIconButton } }
              size="small"
              onClick={ handleEdit.bind(null, option) }
            >
              <EditIcon />
            </IconButton>

            <IconButton
              classes={ { sizeSmall: classes.smallIconButton } }
              size="small"
              onClick={ handleDelete.bind(null, option) }
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      );
    }

    return result;
  }, [classes, handleDelete, handleAddMaterialPreset, handleEdit]);

  return (
    <>
      <Autocomplete
        freeSolo
        options={ presetOptions }
        getOptionLabel={ getOptionLabel }
        getOptionSelected={ getOptionSelected }
        onChange={ handleChange }
        onBlur={ handleBlur }
        ref={ ref }
        classes={ { option: classes.muiOption } }
        value={ value || defaultValue }
        disabled={ disabled }
        renderInput={ (params) => (
          <TextField
            { ...params }
            classes={ {
              root: classes.textFieldRoot,
            } }
            label={ label }
            variant="outlined"
            InputProps={ {
              ...params.InputProps,
            } }
          />
        ) }
        renderOption={ renderPresetOption }
      />

      <AlertDialog
        open={ alertOpen }
        ariaAction={ ariaAction }
        title={ title }
        onAgree={ handleAgree }
        onDisagree={ handleDisagree }
      />
    </>
  );
});

export default memo(MaterialPresetTextField);
