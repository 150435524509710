import { createAction } from '@reduxjs/toolkit';

export const loadCompanies = createAction('companies/loadCompanies');
export const createCompany = createAction('companies/createCompany');
export const createCompanyError = createAction('companies/createCompanyError');
export const createCompanySuccess = createAction('companies/createCompanySuccess');
export const updateCompany = createAction('companies/updateCompany');
export const updateCompanyError = createAction('companies/updateCompanyError');
export const updateCompanySuccess = createAction('companies/updateCompanySuccess');
export const deleteCompany = createAction('companies/deleteCompany');
export const generateApiToken = createAction('companies/generateApiToken');
export const resetCompanyState = createAction('companies/resetCompanyState');
