import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { attributeDefinitionsApi } from '@api';
import {
  LOAD_ATTRIBUTE_DEFINITIONS,
  CREATE_ATTRIBUTE_DEFINITION,
  createAttributeDefinitionError,
  createAttributeDefinitionSuccess,
  EDIT_ATTRIBUTE_DEFINITION,
  editAttributeDefinitionError,
  editAttributeDefinitionSuccess,
  DELETE_ATTRIBUTE_DEFINITION,
  loadAttributeDefinitions,
  loadAttributeDefinitionsSuccess,
  loadAttributeDefinitionsFail,
} from './actions';

function* fetchAttributeDefinitionsList({ entityTypes }) {
  try {
    const { items } = yield call(attributeDefinitionsApi.getAttributeDefinitions, entityTypes);
    yield put(loadAttributeDefinitionsSuccess(items));
  } catch (error) {
    yield put(loadAttributeDefinitionsFail(error));
  }
}

export function* fetchCreateAttributeDefinition({ attributeDefinition }) {
  try {
    yield call(attributeDefinitionsApi.createAttributeDefinition, attributeDefinition);
    yield put(loadAttributeDefinitions());
    yield put(createAttributeDefinitionSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(createAttributeDefinitionError(error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* fetchEditAttributeDefinition({ attributeDefinition }) {
  try {
    yield call(attributeDefinitionsApi.editAttributeDefinition, attributeDefinition);
    yield put(loadAttributeDefinitions());
    yield put(editAttributeDefinitionSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(editAttributeDefinitionError(error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* fetchDeleteAttributeDefinition({ id }) {
  try {
    yield call(attributeDefinitionsApi.deleteAttributeDefinition, id);
    yield put(loadAttributeDefinitions());
  } catch (error) {
    console.error(error);
  }
}

export default function* attributeDefinitionsSaga() {
  yield all([
    takeEvery(LOAD_ATTRIBUTE_DEFINITIONS.START, fetchAttributeDefinitionsList),
    takeEvery(CREATE_ATTRIBUTE_DEFINITION, fetchCreateAttributeDefinition),
    takeEvery(EDIT_ATTRIBUTE_DEFINITION, fetchEditAttributeDefinition),
    takeEvery(DELETE_ATTRIBUTE_DEFINITION, fetchDeleteAttributeDefinition),
  ]);
}
