import {
  RENDER_PRODUCTS_LIST,
  RENDER_PRODUCTS_COUNTS,
  RESET_PRODUCTS,
  RENDER_PRODUCT,
  SORTED_ID,
  CREATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_SUCCESS,
  DELETE_VARIANT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_VARIANT,
  UPDATE_VARIANT,
  CREATE_PRODUCT_ERROR,
  UPDATE_PRODUCT_ERROR,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  CREATE_VARIANT_ERROR,
  UPDATE_VARIANT_ERROR,
  CREATE_VARIANTS_CONFIG,
  CREATE_VARIANTS_CONFIG_SUCCESS,
  DELETE_VARIANTS_CONFIG_SUCCESS,
  RESET_PRODUCT_API_STATE,
} from './actions';
import { SortId } from '@app/sort/sortValues';

import { translationsToFields } from '@shared/utils/translations';

const defaultProducts = {
  items: [],
  total: 0,
};

const initialState = {
  products: defaultProducts,
  sorting: {
    id: SortId,
    label: 'A-Z',
  },
  product: null,
  counts: [],
  sortedId: SortId.AtoZ,
  state: 'initial',
  error: null,
};

function onCreateVariantSuccess(state, action) {
  const newState = { ...state };

  const newVariant = action.variant;
  newVariant.translatedFields = translationsToFields(newVariant.translations);
  delete newVariant.translations;

  newState.product.childProducts.push(newVariant);
  newState.product.childProducts = [...newState.product.childProducts];
  newState.state = 'success';
  return newState;
}

function onUpdateVariantSuccess(state, action) {
  const newState = { ...state };
  const variantIndex = newState.product.childProducts.findIndex((item) => item.id === action.variant.id);

  if (variantIndex >= 0) {
    const newVariant = action.variant;
    newVariant.translatedFields = translationsToFields(newVariant.translations);
    delete newVariant.translations;

    newState.product.childProducts[variantIndex] = newVariant;
    newState.product.childProducts = [...newState.product.childProducts];
  }

  newState.state = 'success';

  return newState;
}

function onDeleteVariantSuccess(state, action) {
  const newState = { ...state };
  newState.product.childProducts = [...newState.product.childProducts.filter((item) => item.id !== action.id)];

  return newState;
}

function onCreateVariantsConfigSuccess(state, action) {
  const newState = { ...state };

  newState.product.childrenConfig = action.response.childrenConfig;
  newState.state = 'success';

  return newState;
}

function onDeleteVariantsConfigSuccess(state) {
  const newState = { ...state };
  newState.product.childrenConfig = [];

  return newState;
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case RENDER_PRODUCTS_LIST: {
      const productItems = action.products.items
        .map((product) => {
          product.translatedFields = translationsToFields(product.translations);
          product.childProducts = product.childProducts.map((childProduct) => {
            childProduct.translatedFields = translationsToFields(childProduct.translations);
            delete childProduct.translations;
            return childProduct;
          });
          delete product.translations;
          return product;
        });
      return {
        ...state,
        products: { ...action.products, items: productItems },
      };
    }
    case RENDER_PRODUCTS_COUNTS:
      return {
        ...state,
        counts: action.payload,
      };
    case RESET_PRODUCTS:
      return {
        ...state,
        products: defaultProducts,
      };
    case RENDER_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case CREATE_VARIANT_SUCCESS:
      return onCreateVariantSuccess(state, action);
    case UPDATE_VARIANT_SUCCESS:
      return onUpdateVariantSuccess(state, action);
    case DELETE_VARIANT_SUCCESS:
      return onDeleteVariantSuccess(state, action);
    case CREATE_VARIANTS_CONFIG_SUCCESS:
      return onCreateVariantsConfigSuccess(state, action);
    case DELETE_VARIANTS_CONFIG_SUCCESS:
      return onDeleteVariantsConfigSuccess(state, action);
    case SORTED_ID:
      return {
        ...state,
        sortedId: action.value,
      };
    case CREATE_PRODUCT:
    case UPDATE_PRODUCT:
    case CREATE_VARIANT:
    case UPDATE_VARIANT:
    case CREATE_VARIANTS_CONFIG:
      return {
        ...state,
        state: 'processing',
      };
    case CREATE_PRODUCT_ERROR:
    case CREATE_VARIANT_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'CREATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case UPDATE_PRODUCT_ERROR:
    case UPDATE_VARIANT_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'UPDATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        state: 'success',
      };
    case RESET_PRODUCT_API_STATE:
      return {
        ...state,
        state: 'initial',
        error: null,
      };
    case DELETE_PRODUCT_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
        };
      }
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
