import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Breadcrumbs from '@shared/components/breadcrumbs';
import Dropdown from '@pages/statistics/modal/selector';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, makeStyles, TextField } from '@material-ui/core';
import Paper from '@shared/components/paper';
import Button from '@shared/components/button';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies/reducers';
import {UserRole} from '@shared/models';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { Field } from '@shared/views/form-dialog';

const BREADCRUMBS_ITEMS = [{ name: 'Limits' }];

const useStyles = makeStyles({
  settingsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '40px',
    padding: '20px',
    width: '100%',
  },
  paper: {
    height: '400px',
    width: '50%',
  },
});

const LimitsStats = () => {
  const curCompany = useSelector((state) => state.app.company);
  const companies = useSelector((state) => state.companies.companies);
  const curUser = useSelector((state) => state.app.user);
  const [selectedCompany, setSelectedCompany] = useState(curCompany);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { updateCompany } = useActions(actions);
  const { control, formState: { errors: fieldsErrors }, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({
      name: selectedCompany?.name,
      userRoomViews: selectedCompany?.userRoomViews || 1000,
      designedRoomViews: selectedCompany?.designedRoomViews || 1000,
      productViews: selectedCompany?.productViews || 1000,
    });
  }, [selectedCompany, reset]);
  useEffect(() => {
    setIsSuperAdmin(curUser.role !== UserRole.COMPANY_ADMIN && curUser.role !== UserRole.COLLABORATOR);
  }, []);

  const classes = useStyles();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    setSnackbarOpen(true);
    updateCompany({ id: selectedCompany.id, company: data });
  });

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          {isSuperAdmin ? (
            <Dropdown
              companies={ companies }
              onSelectCompany={ setSelectedCompany }
              curCompany={ curCompany }
            />
          ) : null}

        </TopNav>
      </PageHead>
      <PageContent>
        <Paper elevation={ 8 } className={ classes.paper }>
          <form noValidate onSubmit={ onSubmit } className={ classes.settingsContainer }>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="userRoomViews"
                  control={ control }
                  defaultValue="1000"
                  rules={ {
                    required: 'Required',
                  } }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id="userRoom"
                      variant="outlined"
                      label="User room views"
                      type="number"
                      InputProps={ {
                        readOnly: !isSuperAdmin,
                      } }
                      required
                    />
                  ) }
                />
              </FormControl>
            </Field>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="designedRoomViews"
                  control={ control }
                  defaultValue="1000"
                  rules={ {
                    required: 'Required',
                  } }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id="designedRoomViews"
                      variant="outlined"
                      label="Designed rooms views"
                      type="number"
                      InputProps={ {
                        readOnly: !isSuperAdmin,
                      } }
                      required
                    />
                  ) }
                />
              </FormControl>
            </Field>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="productViews"
                  control={ control }
                  defaultValue="1000"
                  rules={ {
                    required: 'Required',
                  } }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id="productViews"
                      variant="outlined"
                      label="product views"
                      type="number"
                      InputProps={ {
                        readOnly: !isSuperAdmin,
                      } }
                      required
                    />
                  ) }
                />
              </FormControl>
            </Field>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={ !isSuperAdmin }
            >
              Submit
            </Button>
          </form>
          <Snackbar
            open={ snackbarOpen }
            autoHideDuration={ 3000 }
            onClose={ handleSnackbarClose }
          >
            <Alert
              severity="success"
              style={ {
                width: '100%',
                fontSize: '14px',
                padding: '5px',
                borderRadius: '8px',
              } }
            >
              Submitted
            </Alert>
          </Snackbar>
        </Paper>
      </PageContent>
    </PageContainer>
  );
};

export default LimitsStats;
