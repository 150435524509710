import { forwardRef, memo, useCallback, useMemo } from 'react';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const LightTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
  required,
}, ref) => {
  const options = [
    'Warm Light',
    'Neutral Light',
    'Cold Light',
    'Moon Light',
  ];
  const defaultValue = useMemo(() => ('Warm Light'), []);

  const handleChange = useCallback((_, data) => {
    if (data) {
      onChange(data);
    } else {
      const option = options.find((op) => op === data);
      if (option) {
        onChange(option);
        return;
      }
      if (value === data) {
        return;
      }
      onChange(defaultValue);
    }
  }, [onChange, options, name, value, defaultValue]);

  return (
    <Autocomplete
      freeSolo
      disablePortal={ false }
      options={ options }
      onChange={ handleChange }
      onBlur={ (e) => handleChange(e, e.target.value) }
      ref={ ref }
      value={ value || defaultValue }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          variant="outlined"
          required={ required }
          InputProps={ {
            ...params.InputProps,
          } }
        />
      ) }
    />
  );
});

export default memo(LightTextField);
