import * as React from 'react';

const StylingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="#BCC8D9"
    viewBox="0 0 20 20"
  >
    <path
      fill="#9CACC2"
      d="M18.27 1.73a2.494 2.494 0 0 0-3.73.233L11.07 6.428 9.626
       4.983a2.22 2.22 0 0 0-1.581-.655c-.598 0-1.16.233-1.582.655L1.458
       9.988c-.61.61-.61 1.604 0 2.214l6.34 6.34A1.56 1.56 0 0 0 8.905
       19c.4 0 .802-.153 1.107-.458l5.005-5.005c.422-.422.655-.984.655-1.582
       0-.597-.233-1.159-.655-1.581l-1.445-1.445 4.465-3.469a2.493 2.493
       0 0 0 .233-3.73M8.553 17.787l-.559-.56.807-.806a.535.535
       0 0 0-.755-.756l-.807.807-.357-.357 1.554-1.554a.534.534
       0 1 0-.755-.756L6.126 15.36 4.64 13.874l.808-.807a.534.534
       0 1 0-.756-.756l-.807.807-.357-.357 1.554-1.554a.534.534
       0 1 0-.755-.756l-1.555 1.555-.559-.56a.5.5 0 0 1 0-.702L4.927 8.03l7.043
       7.043-2.714 2.714a.5.5 0 0 1-.703 0m4.173-3.47L5.683 7.274l.64-.639
       7.042 7.043zm1.877-2.362c0 .313-.121.606-.342.826l-.14.141-6.732-6.731-.311-.312.14-.14c.221-.221.515-.342.827-.342s.605.121.825.342l5.391
       5.39c.221.221.343.514.343.826m3.326-8.374a1.42 1.42 0 0 1-.548 1.035l-4.57 3.551-.978-.977 3.55-4.571a1.423 1.423 0 0 1 2.546.962"
    />
    <path
      fill="#BCC8D9"
      d="M16.464 3.002c-.28 0-.547.246-.534.535s.235.534.534.534c.28 0 .548-.246.535-.534a.544.544 0 0 0-.535-.535"
    />
  </svg>
);

export default StylingIcon;
