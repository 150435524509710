import * as React from 'react';

const VisualIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
    <g clipPath="url(#clip0_1452_11115)">
      <g fill="#BCC8D9" clipPath="url(#clip1_1452_11115)">
        <path d="M18.63 7.577A10.61 10.61 0 0 0 11 4.25a10.6 10.6 0 0 0-7.63 3.327A4.84 4.84 0 0 0 2 10.997v.005a4.84 4.84 0 0 0 1.37 3.421
          A10.61 10.61 0 0 0 11 17.75a10.6 10.6 0 0 0 7.63-3.327A4.84 4.84 0 0 0 20 10.998a4.84 4.84 0 0 0-1.37-3.421m-1.07 5.795
          A9.14 9.14 0 0 1 11 16.25a9.14 9.14 0 0 1-6.56-2.878 3.4 3.4 0 0 1-.94-2.37v-.004a3.4 3.4 0 0 1 .94-2.37A9.14 9.14 0 0 1 11
          5.75a9.14 9.14 0 0 1 6.56 2.878 3.463 3.463 0 0 1 0 4.744"
        />
        <path d="M11 6.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9m0 7.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
        <path d="M11 9.125a.75.75 0 1 0 0 1.5.375.375 0 0 1 .375.375.75.75 0 1 0 1.5 0A1.877 1.877 0 0 0 11 9.125" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1452_11115">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
      <clipPath id="clip1_1452_11115">
        <path fill="#fff" d="M2 2h18v18H2z" />
      </clipPath>
    </defs>
  </svg>
);

export default VisualIcon;
