import { useCallback, useState, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Fields,
  PreviewContainer,
} from '@shared/views/form-dialog';
import Preview from './Preview';
import { Fieldset } from './fieldset';
import { makeStyles } from '@material-ui/core/styles';
import VariantsConfigurationMaterialTable from '@pages/products/modal/VariantsConfigurationMaterialTable';
import { ModelFormats } from '../constants';
import useProductPreview from '@pages/products/hooks/useProductPreview';
import TextureSizeController from '@pages/products/modal/TextureSizeController';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid #E2EAF3',
    borderBottom: '1px solid #E2EAF3',
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },
  tab: {
    padding: theme.spacing(2.5),
    minWidth: '157px',

    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
      color: '#5F6B79',
      fontSize: '1.8rem',
      lineHeight: '27px',
      textTransform: 'initial',
    },

    '&.Mui-selected': {
      backgroundColor: '#E2EAF3',

      '& .MuiTab-wrapper': {
        color: theme.palette.common.darkBlue,
      },
    },
  },
  tabContent: {
    padding: '27px 20px 27px 20px',
    overflow: 'auto',
    flex: '1 1 auto',
    maxWidth: '370px',
  },
  description: {
    '& input': {
      height: '77px',
      paddingTop: '0',
    },
  },
  previewContainer: {
    justifyContent: 'center',
    padding: theme.spacing(0, 2.5, 0, 0.5),
    maxWidth: '722px',
    flexBasis: 'calc(100vh - 241px)', // 722px for full hd non full screen
  },
}));

const VariantsConfigurationFormFieldset = ({
  onAddMaterialPreset,
  onEditMaterialPreset,
  renewable,
}) => {
  const classes = useStyles();
  const {
    control,
  } = useFormContext();
  const [tab, setTab] = useState(0);
  const [object, setObject] = useState();
  const [camera, setCamera] = useState();
  const [gl, setGl] = useState();
  const [scene, setScene] = useState();
  const [cameraInitialState, setCameraInitialState] = useState();

  const { renderLightPresetsController } = useProductPreview({ object, gl });

  const productName = useWatch({ control, name: Fieldset.Name });
  const textureSize = useWatch({ control, name: Fieldset.TextureSize });
  const currentEnvironment = useWatch({ control, name: Fieldset.Environment });

  const childrenConfig = useWatch({ control, name: Fieldset.ChildrenConfig });
  const materials = useMemo(() => childrenConfig.map((item) => ({ ...item, material: item.material?.id ? item.material : item.productMaterial })),
    [childrenConfig]);

  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, []);

  return (
    <Box className={ classes.root }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ tab }
        onChange={ handleTabChange }
        aria-label="Tabs"
        style={ { borderRight: '1px solid #E2EAF3', flex: '0 0 auto' } }
        TabIndicatorProps={ { style: { display: 'none' } } }
      >
        <Tab label="Visualization" className={ classes.tab } />
      </Tabs>
      <div
        role="tabpanel"
        className={ classes.tabContent }
        hidden={ tab !== 0 }
        id="vertical-tabpanel-0"
        aria-labelledby="vertical-tab-0"
      >
        <Fields>
          <Controller
            name={ Fieldset.Model }
            type="hidden"
            control={ control }
            render={ () => {} }
          />

          <VariantsConfigurationMaterialTable
            name={ Fieldset.ChildrenConfig }
            control={ control }
            onAddMaterialPreset={ onAddMaterialPreset }
            onEditMaterialPreset={ onEditMaterialPreset }
            object={ object }
            camera={ camera }
            cameraInitialState={ cameraInitialState }
            gl={ gl }
            scene={ scene }
          />
        </Fields>
      </div>

      <PreviewContainer className={ classes.previewContainer }>
        <Preview
          name={ Fieldset.ModelData }
          fileName={ productName }
          supportedFormats={ ModelFormats }
          generateThumbnail={ false }
          renderModelViewerExtraContent={ (object) => (
            <>
              { renderLightPresetsController(object) }
              <TextureSizeController name={ Fieldset.TextureSize } />
            </>
          ) }
          onLoad={ (object, camera, gl, scene) => {
            setObject(object);
            setCamera(camera);
            setCameraInitialState({
              position: [...camera.position],
              aspect: camera.aspect,
            });
            setGl(gl);
            setScene(scene);
          } }
          control={ control }
          renewable={ renewable }
          materials={ materials }
          environment={ currentEnvironment }
          size={ textureSize }
        />
      </PreviewContainer>
    </Box>
  );
};

export default VariantsConfigurationFormFieldset;
