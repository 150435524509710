import { createSlice } from '@reduxjs/toolkit';
import { SortId } from '@app/sort/sortValues';
import {
  loadCompanies,
  createCompany,
  createCompanyError,
  createCompanySuccess,
  updateCompany,
  updateCompanyError,
  updateCompanySuccess,
  deleteCompany,
  generateApiToken,
  resetCompanyState,
} from './actions';

const initialState = {
  companies: [],
  sorting: {
    id: SortId.AtoZ,
    label: 'A-Z',
  },
  error: null,
  createSuccess: false,
  updateSuccess: false,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    renderCompanies: (state, action) => {
      state.companies = action.payload;
    },
    createCompanyError: (state, action) => {
      state.error = action.payload;
      state.createSuccess = false;
    },
    createCompanySuccess: (state) => {
      state.error = null;
      state.createSuccess = true;
    },
    updateCompanyError: (state, action) => {
      state.error = action.payload;
      state.updateSuccess = false;
    },
    updateCompanySuccess: (state) => {
      state.error = null;
      state.updateSuccess = true;
    },
    resetCompanyState: (state) => {
      state.createSuccess = false;
      state.updateSuccess = false;
      state.error = null;
    },
  },
});

export const actions = {
  ...companiesSlice.actions,
  loadCompanies,
  createCompany,
  createCompanyError,
  createCompanySuccess,
  updateCompany,
  updateCompanyError,
  updateCompanySuccess,
  deleteCompany,
  generateApiToken,
  resetCompanyState,
};

export default companiesSlice.reducer;
