import { useState } from 'react';
import { FormDialog } from '@shared/views/form-dialog';
import AttributeDefinitionForm from './AttributeDefinitionForm';
import FormFieldset from './FormFieldset';
import FormTitle from './FormTitle';
import { AVAILABLE_LANGUAGES } from '@shared/utils/translations';
import { useDispatch, useSelector } from 'react-redux';
import { resetAttributeApiState } from '@store/attribute-definitions/actions';

const AttributeDefinitionModal = ({ open, onClose, type, attributeDefinition }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));
  const apiState = useSelector((state) => state.attributeDefinitions.state);
  const dispatch = useDispatch();

  const handle = () => {
    dispatch(resetAttributeApiState());
    onClose();
    setSelectedLanguage(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));
  };

  return (
    <FormDialog
      open={ open }
      isSubmitDisabled={ apiState === 'processing' }
      Form={ AttributeDefinitionForm }
      FormProps={ {
        open,
        type,
        data: attributeDefinition,
        onSubmit: handle,
      } }
      title={ (
        <FormTitle
          actionType={ type }
          options={ AVAILABLE_LANGUAGES }
          onLangChange={ setSelectedLanguage }
          selectedLanguage={ selectedLanguage }
        />
      ) }
      content={ (
        <FormFieldset
          attributeDefinition={ attributeDefinition }
          lang={ selectedLanguage.key }
        />
      ) }
      onCancel={ handle }
      onClose={ handle }
    />
  );
};

export default AttributeDefinitionModal;
