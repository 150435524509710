import { createAction } from '@reduxjs/toolkit';

export const loadUsers = createAction('users/loadUsers');
export const createUser = createAction('users/createUser');
export const createUserError = createAction('users/createUserError');
export const createUserSuccess = createAction('users/createUserSuccess');
export const updateUser = createAction('users/updateUser');
export const updateUserError = createAction('users/updateUserError');
export const updateUserSuccess = createAction('users/updateUserSuccess');
export const deleteUser = createAction('users/deleteUser');
export const resetUserState = createAction('users/resetUserState');
