import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions as sideMenuActions } from '@store/app/index';

import UsageAndLimits from '@pages/statistics';
import { useSelector } from 'react-redux';
import { actions } from '@store/companies/reducers';
import { selectCurrentCompany } from '@store/app/selectors';
import { useEffect } from 'react';
import { isAdmin } from '@shared/models';

const Statistics = withAuth(() => {
  const curUser = useSelector((state) => state.app.user);

  const { loadCompanies } = useActions(actions);
  const { showMenu } = useActions(sideMenuActions);
  const company = useSelector(selectCurrentCompany);

  useEffect(() => {
    showMenu();
    window.scrollTo(0, 0);
    if (isAdmin(curUser)) loadCompanies();
  }, [loadCompanies, isAdmin, company, curUser]);
  return (
    <UsageAndLimits />
  );
});

export default Statistics;
