import { useCallback, useEffect } from 'react';
import CreateMaterialPresetForm from './MaterialPresetForm';
import MaterialPresetFormFieldset from '../MaterialPresetFormFieldset';
import ProductFormDialog from '../ProductFormDialog';
import { useDispatch, useSelector } from 'react-redux';
import { loadMaterialAttributeDefinitionList, resetMaterialPresetApiState } from '@store/material-presets/actions';
import { selectMaterialPresetAttributeDefinitions } from '@store/material-presets/selectors';

const MaterialPresetFormDialog = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const attributeDefinitions = useSelector(selectMaterialPresetAttributeDefinitions);

  useEffect(() => {
    dispatch(loadMaterialAttributeDefinitionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(async (formData, close = true) => {
    if (close) {
      dispatch(resetMaterialPresetApiState());
      onClose();
    }
  }, [dispatch, onClose]);

  const handleCancel = useCallback(() => {
    dispatch(resetMaterialPresetApiState());
    onClose();
  }, [dispatch, onClose]);

  return (
    <ProductFormDialog
      open={ open }
      Form={ CreateMaterialPresetForm }
      FormProps={ {
        open,
        onSubmit: handleSubmit,
      } }
      title="Select Materials Attributes"
      content={ (
        <MaterialPresetFormFieldset
          attributeDefinitions={ attributeDefinitions }
        />
      ) }
      onCancel={ handleCancel }
      onClose={ onClose }
    />
  );
};

export default MaterialPresetFormDialog;
