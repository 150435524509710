import { all, call, put, takeEvery } from 'redux-saga/effects';

import { companiesApi } from '@api';
import { actions } from './reducers';
import { actions as appActions } from '@store/app/index';

const {
  loadCompanies,
  createCompany,
  createCompanyError,
  createCompanySuccess,
  updateCompany,
  updateCompanyError,
  updateCompanySuccess,
  deleteCompany,
  renderCompanies,
  generateApiToken,
  resetCompanyState,
} = actions;

function* loadCompaniesSaga() {
  try {
    const { items } = yield call(companiesApi.getCompanies);
    yield put(renderCompanies(items));
  } catch (e) {
    console.error(e);
  }
}

function* createCompanySaga(action) {
  try {
    yield put(resetCompanyState());
    yield call(companiesApi.createCompany, action.payload);
    yield put(createCompanySuccess());
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
    yield put(createCompanyError(e.message || 'Property name should be unique'));
    yield put(appActions.addMessage({ message: 'Property name should be unique', type: 'error' }));
  }
}

function* updateCompanySaga(action) {
  try {
    const { id, company } = action.payload;
    yield put(resetCompanyState());
    yield call(companiesApi.updateCompany, id, company);
    yield put(updateCompanySuccess());
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
    yield put(updateCompanyError(e.message || 'Property name should be unique'));
    yield put(appActions.addMessage({ message: 'Property name should be unique', type: 'error' }));
  }
}

function* generateCompanyApiTokenSaga(action) {
  try {
    const { id } = action.payload;
    yield call(companiesApi.generateCompanyApiToken, id);
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
  }
}

function* deleteCompanySaga(action) {
  try {
    yield call(companiesApi.updateCompany, action.payload.id, { ...action.payload, isDeleted: true });
    yield put(loadCompanies());
  } catch (e) {
    console.error(e);
  }
}

export default function* companiesSaga() {
  yield all([
    takeEvery(loadCompanies, loadCompaniesSaga),
    takeEvery(createCompany, createCompanySaga),
    takeEvery(updateCompany, updateCompanySaga),
    takeEvery(deleteCompany, deleteCompanySaga),
    takeEvery(generateApiToken, generateCompanyApiTokenSaga),
  ]);
}
