import { forwardRef, memo, useCallback } from 'react';
import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';

const FontTextField = forwardRef(({
  label,
  options,
  value,
  helperText,
  error,
  onChange,
  ...rest
}, ref) => {
  const handleChange = useCallback((event, data) => {
    onChange(data?.value);
  }, [onChange]);

  const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.label || '';
  };

  const selectedOption = options.find(option => option.value === value) || null;

  return (
    <Autocomplete
      options={ options }
      value={ selectedOption }
      getOptionLabel={ getOptionLabel }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          helperText={ helperText }
          error={ !!error }
          fullWidth
          required
        />
      ) }
      onChange={ handleChange }
      ref={ ref }
      disablePortal={ false }
      { ...rest }
    />
  );
});

export default memo(FontTextField);
