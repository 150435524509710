import * as React from 'react';

function StatsIcon(props) {
  return (
    <svg
      width={ 20 }
      height={ 20 }
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      { ...props }
    >
      <path
        d="M1 19h18M3.135 19v-7.322M7.71 19V8.627M12.29
         19v-7.322M16.865 19V7.102M4.215 6.023l2.419-2.419M8.791 3.604l2.419
          2.419M13.367 6.023l2.42-2.419M7.711 4.05a1.525 1.525 0 100-3.05 1.525
           1.525 0 000 3.05zM12.29 8.627a1.525 1.525 0 100-3.05 1.525 1.525 0 000
            3.05zM3.135 8.627a1.525 1.525 0 100-3.05 1.525 1.525 0 000 3.05zM16.865
             4.05a1.525 1.525 0 100-3.05 1.525 1.525 0 000 3.05z"
        stroke="#BCC8D9"
        strokeWidth={ 1.5 }
        strokeMiterlimit={ 10 }
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StatsIcon;
