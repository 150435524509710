import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  TextField,
  Fields,
  Field,
  GroupField,
  PreviewContainer,
  ModelViewer,
} from '@shared/views/form-dialog';
import Preview from './Preview';
import ModelTextField from './ModelTextField';
import MeshTable from './MeshTable';
import { Fieldset, InputLabelProps } from './fieldset';
import { makeStyles } from '@material-ui/core/styles';
import AttributesTable from '@shared/components/attributes-table/AttributesTable';
import classNames from 'classnames';
import ImageFullSize from '@shared/components/image-full-size';
import SquareWrapper from '@shared/components/page-parts/SquareWrapper';
import VariantsList from '@pages/products/modal/VariantsList';
import { ModelFormats } from '../constants';
import useProductPreview from '@pages/products/hooks/useProductPreview';
import { isFormDirty } from '@pages/products/utils';
import TranslatedFieldsGroup from '@shared/components/translations/TranslatedGroupFields';
import CurrencyTextField from './CurrencyTextField';
import TextureSizeController from '@pages/products/modal/TextureSizeController';
import useFormValues from '@shared/hooks/useFormValues';
import { AVAILABLE_LANGUAGES } from '@shared/utils/translations';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';
import Checkbox from '@shared/components/checkbox';
import FormControlLabel from '@shared/components/form-control-label';


const useStyles = makeStyles((theme) => ({
  box: {
    borderTop: '1px solid #E2EAF3',
    borderBottom: '1px solid #E2EAF3',
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: '1px solid #E2EAF3',
    flex: '0 0 auto',
  },
  tab: {
    padding: theme.spacing(2.5),
    minWidth: '157px',

    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
      color: '#5F6B79',
      fontSize: '1.8rem',
      lineHeight: '27px',
      textTransform: 'initial',
    },

    '&.Mui-selected': {
      backgroundColor: '#E2EAF3',

      '& .MuiTab-wrapper': {
        color: theme.palette.common.darkBlue,
      },
    },
  },
  tabpanel: {
    overflow: 'auto',
    flex: '1 1 auto',
    maxWidth: '370px',
  },
  tabpanel0: {
    padding: '27px 30px 27px 30px',
  },
  tabpanel1: {
    padding: '27px 20px 27px 20px',
  },
  tabpanel2: {
    padding: '28px 20px 28px 24px',
  },
  attributesHeading: {
    fontSize: '18px',
    padding: '0 8px 8px',
    color: '#334D6E',
  },
  description: {
    '& input': {
      height: '77px',
      paddingTop: '0',
    },
  },
  previewContainer: {
    justifyContent: 'center',
    padding: theme.spacing(0, 2.5, 0, 0.5),
    maxWidth: '722px',
    flexBasis: 'calc(100vh - 241px)', // 722px for full hd non full screen
  },
  hidden: {
    display: 'none',
  },
  variantsTabContent: {
    padding: '27px 20px 27px 20px',
    overflow: 'auto',
    flex: '1 1 auto',
    maxWidth: '370px',
  },
  noVariantsContent: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '30px',
  },
  variantPreviewContainer: {
    position: 'relative',
    border: `.1rem dashed ${theme.palette.common.divider}`,
    background: '#FCFDFF',
    borderRadius: '.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
}));

// unique prop in certain object inside form
const formIdKeysMap = {
  'materials.material': 'id',
  'attributes.value': 'attribute',
  model: 'id',
};

// props that should be skipped during "dirty" check
const dirtyCheckExclusion = [
  'childProducts',
  'childrenConfig',
  'environment',
  'materials.mesh',
  'materials.group.translations',
  'attributes.definition',
  'attributes.translations',
  '.textureSize',
];

const FormFieldset = ({
  data,
  renewable,
  onAddVariantClick,
  onEditVariantClick,
  onConfigureVariants,
  initialtab = 0,
  onTabChange,
  onObject,
}) => {
  const classes = useStyles();
  const form = useFormContext();

  const {
    control,
    setValue,
    formState: { errors: fieldsErrors, defaultValues },
  } = form;

  const meshTableRef = useRef();
  const [tab, setTab] = useState(initialtab);
  const [object, setObject] = useState();
  const [gl, setGl] = useState();
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isModelLoading, setIsModelLoading] = useState(false);

  const allFormFieldValues = useFormValues(form);

  const attributeDefinitions = useSelector(selectAttributeDefinitions);

  const materialsWatch = useWatch({ control, name: Fieldset.Materials });

  // Workaround to watch recent materials
  const materials = useMemo(() => [...allFormFieldValues.materials], [materialsWatch, allFormFieldValues.materials]);

  const { renderLightPresetsController, renderThumbnailController, renderActions } = useProductPreview({ object, gl, name: allFormFieldValues.name });

  const currentEnvironment = useWatch({ control, name: Fieldset.Environment });

  const currentModel = useWatch({ control, name: Fieldset.Model });

  const [selectedLanguage, setSelectedLanguage] = useState(AVAILABLE_LANGUAGES.find((lng) => lng.key === 'en'));

  const useLang = useMemo(() => selectedLanguage && selectedLanguage.key !== 'en', [selectedLanguage]);

  const companySelector = (state) => state.app.company;

  const companyA = useSelector(companySelector);
  const variationsAvaliable = companyA.variationsAccess;

  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);

    if (onTabChange) {
      onTabChange(newValue);
    }
  }, [onTabChange]);

  const handleVariantSelect = useCallback((variant) => {
    setSelectedVariant(variant);
  }, []);

  const handleLoad = useCallback((object, camera, gl) => {
    setObject(object);
    setGl(gl);
    setIsModelLoading(false);
  }, []);

  useEffect(() => {
    if (onObject) {
      onObject(object);
    }
  }, [object]);

  useEffect(() => {
    if (currentModel.data.blob) {
      setIsModelLoading(true);
    }
  }, [currentModel.data.blob]);

  // TODO: extract styles to classes
  return (
    <Box className={ classes.box }>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={ tab }
        onChange={ handleTabChange }
        aria-label="Material tabs"
        className={ classes.tabs }
        TabIndicatorProps={ { style: { display: 'none' } } }
      >
        <Tab label="Details" className={ classes.tab } />
        <Tab label="Visualization" className={ classes.tab } />
        <Tab label="Attributes" className={ classes.tab } />
        <Tab
          label="Variations"
          className={ classes.tab }
          disabled={
              !data?.id
              || isFormDirty({
                fieldValues: allFormFieldValues,
                defaultValues,
                dirtyCheckExclusion,
                formIdKeysMap,
              })
              || variationsAvaliable === false
            }
        />
      </Tabs>
      <div
        role="tabpanel"
        className={ classNames(classes.tabpanel, classes.tabpanel0) }
        hidden={ tab !== 0 }
        id="vertical-tabpanel-0"
        aria-labelledby="vertical-tab-0"
      >
        <Fields>
          { tab !== 1 ? (
            <Field>
              { renderThumbnailController() }
            </Field>
          ) : null }

          <Field>
            <Controller
              name={ Fieldset.ExternalId }
              control={ control }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.ExternalId]?.message }
                  label="ID (SKU)"
                  required
                  error={ !!fieldsErrors[Fieldset.ExternalId] }
                />
              ) }
            />
          </Field>
          <TranslatedFieldsGroup
            onLangChange={ setSelectedLanguage }
            selectedLanguage={ selectedLanguage }
          >
            <Field>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
                  : Fieldset.Name }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`
                  : Fieldset.Name }
                control={ control }
                rules={ {
                  required: 'Required',
                } }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    helperText={ useLang
                      ? fieldsErrors[`${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`]?.message
                      : fieldsErrors[Fieldset.Name]?.message }
                    label="Name"
                    required
                    error={ useLang
                      ? !!fieldsErrors[`${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Name}`]
                      : !!fieldsErrors[Fieldset.Name] }
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
            </Field>
            <GroupField>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Price}`
                  : Fieldset.Price }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Price}`
                  : Fieldset.Price }
                control={ control }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    label="Price"
                    InputLabelProps={ InputLabelProps }
                    onChange={ (event) => {
                      const newValue = event.target.value.replace(/[\s,]/g, '');
                      field.onChange(newValue);
                    } }
                  />
                ) }
              />
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Currency}`
                  : Fieldset.Currency }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Currency}`
                  : Fieldset.Currency }
                control={ control }
                render={ ({ field: { value, onChange }, fieldState: { error } }) => (
                  <CurrencyTextField
                    value={ value }
                    onChange={ onChange }
                    error={ error }
                  />
                ) }
              />
            </GroupField>
            <Field>
              <Controller
                name={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Description}`
                  : Fieldset.Description }
                key={ useLang
                  ? `${Fieldset.TranslatedFields}.${selectedLanguage.key}.${Fieldset.Description}`
                  : Fieldset.Description }
                control={ control }
                render={ ({ field }) => (
                  <TextField
                    { ...field }
                    multiline
                    className={ classes.description }
                    label="Description (text/html)"
                    InputLabelProps={ InputLabelProps }
                  />
                ) }
              />
            </Field>
          </TranslatedFieldsGroup>
          <Field>
            <Controller
              name={ Fieldset.MovementType }
              key={ Fieldset.MovementType }
              control={ control }
              render={ ({ field }) => (
                <FormControlLabel
                  name={ Fieldset.MovementType }
                  label="Vertical movement"
                  className={ classes.root }
                  control={ (
                    <Checkbox
                      { ...field }
                      checked={ field.value === 'WALL' }
                      onChange={ (event) => {
                        if (event.currentTarget.checked) {
                          setValue(Fieldset.MovementType, 'WALL');
                          return;
                        }
                        setValue(Fieldset.MovementType, 'FLOOR');
                      } }
                    />
                ) }
                />
              ) }
            />
          </Field>
        </Fields>
      </div>
      <div
        role="tabpanel"
        className={ classNames(classes.tabpanel, classes.tabpanel1) }
        hidden={ tab !== 1 }
        id="vertical-tabpanel-1"
        aria-labelledby="vertical-tab-1"
      >
        <Fields>
          { tab === 1 ? (
            <Field>
              { renderThumbnailController() }
            </Field>
          ) : null }

          <Field style={ { position: 'relative', paddingBottom: 0 } }>
            <Controller
              name={ Fieldset.Model }
              control={ control }
              defaultValue={ {} }
              rules={ {
                required: 'Required',
              } }
              render={ ({ field }) => (
                <ModelTextField
                  { ...field }
                  helperText={ fieldsErrors[Fieldset.Model]?.message }
                  label="Model"
                  required
                  error={ !!fieldsErrors[Fieldset.Model] }
                  onResetMaterials={ (value) => {
                    control._defaultValues[Fieldset.Materials] = [];
                    meshTableRef.current?.replace(value);
                  } }
                  disabled={ isModelLoading }
                />
              ) }
            />
          </Field>
          <MeshTable
            ref={ meshTableRef }
            name={ Fieldset.Materials }
            control={ control }
            object={ object }
          />
        </Fields>
      </div>
      <div
        role="tabpanel"
        className={ classNames(classes.tabpanel, classes.tabpanel2) }
        hidden={ tab !== 2 }
        id="vertical-tabpanel-2"
        aria-labelledby="vertical-tab-2"
      >
        <div className={ classes.attributesHeading }>Manage Attributes</div>
        <Controller
          name={ Fieldset.Attributes }
          control={ control }
          render={ ({ field }) => (
            <AttributesTable
              attributeDefinitions={ attributeDefinitions }
              { ...field }
            />
          ) }
        />
      </div>
      <div
        role="tabpanel"
        className={ classNames(classes.variantsTabContent,
          { [classes.noVariantsContent]: !data?.childProducts?.length && !data?.childrenConfig?.length }) }
        hidden={ tab !== 3 }
        id="vertical-tabpanel-2"
        aria-labelledby="vertical-tab-2"
      >
        <VariantsList
          data={ data }
          onAddVariantClick={ onAddVariantClick }
          onEditVariantClick={ onEditVariantClick }
          onConfigureVariants={ onConfigureVariants }
          onVariantSelect={ handleVariantSelect }
        />
      </div>

      <PreviewContainer className={ classNames(classes.previewContainer, { [classes.hidden]: tab === 3 }) }>
        <Preview
          name={ Fieldset.ModelData }
          fileName={ allFormFieldValues.name }
          supportedFormats={ ModelFormats }
          generateThumbnail={ false }
          renderModelViewerExtraContent={ (object) => (
            <>
              { renderLightPresetsController(object) }
              <TextureSizeController name={ Fieldset.TextureSize } />
            </>
          ) }
          onLoad={ handleLoad }
          control={ control }
          renewable={ renewable }
          materials={ materials }
          environment={ currentEnvironment }
          size={ allFormFieldValues.textureSize }
        />

        { renderActions() }
      </PreviewContainer>

      {selectedVariant ? (
        <PreviewContainer className={ classNames(classes.previewContainer, { [classes.hidden]: tab !== 3 }) }>
          <div className={ classes.variantPreviewContainer }>
            <SquareWrapper>
              <ImageFullSize>
                <ModelViewer
                  value={ selectedVariant.model }
                  materials={ selectedVariant ? selectedVariant.materials : [] }
                  generateThumbnail={ false }
                  environment={ currentEnvironment }
                />
              </ImageFullSize>
            </SquareWrapper>
          </div>
        </PreviewContainer>
      ) : null}
    </Box>
  );
};

export default FormFieldset;
