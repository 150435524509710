import { useMemo, useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Button from '@shared/components/button';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import useAlertControl, {
  Types,
} from '@shared/components/card-controls/useAlertControl';
import PlusIcon from '@shared/icons/PlusIcon';
import EditIcon from '@resources/icons/EditIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies/reducers';

import CompanyModal, { ActionType } from '../modal';
import CompanyToken from './CompanyToken';
import { selectSortingId, selectCurrentCompany } from '@store/app/selectors';
import Breadcrumbs from '@shared/components/breadcrumbs';
import sortData from '@app/sort';
import { isAdmin, isCompanyAdmin } from '@shared/models';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.2rem',
    },
  },
}));

const BREADCRUMBS_ITEMS = [{ name: 'Companies' }];

const CompaniesList = () => {
  const curUser = useSelector((state) => state.app.user);
  const curCompany = useSelector(selectCurrentCompany);
  const companies = useSelector((state) => state.companies.companies);
  const companySelector = (state) => state.app.company;
  const companyA = useSelector(companySelector);

  const sortingId = useSelector(selectSortingId);
  const { deleteCompany, updateCompany, generateApiToken } = useActions(actions);


  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);

  const sortedCompanies = useMemo(() => sortData(companies, sortingId), [companies, sortingId]);

  const classes = useStyles();

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#00c98f',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#00c98f',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 20,
      height: 20,
      marginTop: '1px',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: '#969696',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => (
    <Switch
      focusVisibleClassName={ classes.focusVisible }
      disableRipple
      classes={ {
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      } }
      { ...props }
    />
  ));
  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    setGridData([...sortedCompanies]);
  }, [sortedCompanies]);

  const handleChange = (event, index) => {
    updateCompany({
      id: gridData[index].id,
      company: {
        name: gridData[index].name,
        active: event.target.checked,
      },
    });

    setGridData(
      gridData.map((item, index2) => (index === index2 ? { ...item, active: event.target.checked } : item)),
    );
  };

  const [
    alertOpen,
    { handleAlertControlClick, handleAgree, handleDisagree, ariaAction, title },
  ] = useAlertControl();

  const onCompanyCreate = () => {
    setModalOpen(true);
    setModalData(null);
    setModalType(ActionType.CREATE);
  };

  const onGenerataApiToken = (company) => {
    generateApiToken({
      id: company.id,
    });
  };

  const onCompanyEdit = (company) => () => {
    setModalOpen(true);
    setModalType(ActionType.UPDATE);
    setModalData(company);
  };

  const onCompanyDelete = (company) => () => {
    handleAlertControlClick(Types.Delete, () => deleteCompany(company))();
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          <>
            {curCompany.default && (
            <Button
              leftPlace={ <PlusIcon /> }
              onClick={ onCompanyCreate }
            >
              Create new
            </Button>
            )}
          </>
        </TopNav>
        <CompanyModal
          open={ modalOpen }
          type={ modalType }
          company={ modalData }
          onClose={ onModalClose }
        />
        <AlertDialog
          open={ alertOpen }
          ariaAction={ ariaAction }
          title={ title }
          onAgree={ handleAgree }
          onDisagree={ handleDisagree }
        />
      </PageHead>
      <PageContent>
        <TableContainer component={ Paper }>
          <Table className={ classes.table }>
            <TableHead>
              <TableRow>
                {isAdmin(curUser) && curCompany.default && (
                  <TableCell>Status</TableCell>
                )}
                <TableCell>Name</TableCell>
                <TableCell>Access Token</TableCell>
                <TableCell>API Token</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isAdmin(curUser) && curCompany.default
                ? (
                  gridData.map((company, index) => (!company.isDeleted ? (
                    <TableRow key={ company.id }>
                      <TableCell>
                        <IOSSwitch
                          size="small"
                          checked={ company.active }
                          disabled={ company.default }
                          onChange={ (event) => handleChange(event, index) }
                        />
                      </TableCell>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>
                        <CompanyToken token={ company.token } />
                      </TableCell>
                      <TableCell>
                        {
                          company.apiToken
                          && <CompanyToken token={ company.apiToken } onRefresh={ onGenerataApiToken.bind(null, company) } />
                        }
                        {
                          !company.apiToken && (
                            <Button
                              color="primary"
                              onClick={ onGenerataApiToken.bind(null, company) }
                            >
                              Generate
                            </Button>
                          )
                        }
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={ onCompanyEdit(company) }>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={ onCompanyDelete(company) } disabled={ company.default }>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null))
                )
                : (
                  <TableRow key={ curCompany.id }>
                    <TableCell>{curCompany.name}</TableCell>
                    <TableCell>
                      <CompanyToken token={ curCompany.token } />
                    </TableCell>
                    <TableCell>
                      {companyA?.publicApiAccess
                      && <CompanyToken token={ curCompany.apiToken } />}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={ onCompanyEdit(curCompany) }
                        disabled={ !isAdmin(curUser) && !isCompanyAdmin(curUser) }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={ onCompanyDelete(curCompany) }
                        disabled={ !isAdmin(curUser) }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </PageContainer>
  );
};

export default CompaniesList;
