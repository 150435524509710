import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@shared/views/form-dialog';
import { Fieldset, getDefaultLightPreset } from '../fieldset';
import { useEffect } from 'react';
import { loadMaterialPresetList } from '@store/material-presets/actions';
import { createVariantsConfig } from '@store/products/actions';

const VariantsConfigurationForm = ({
  children,
  className,
  open,
  productData,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const allEnvironments = useSelector((state) => state.scenes.environments.items);
  const apiState = useSelector((state) => state.products.state);

  useEffect(() => {
    dispatch(loadMaterialPresetList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm({
    defaultValues: {
      [Fieldset.Model]: {
        ...productData.model,
        data: {
          blob: productData.model?.data,
          thumbnail: productData.model?.data?.thumbnail,
          dataType: productData.model?.dataType,
          scale: productData.model?.scale,
        },
      },
      //It supposed to be product's name because the config itself has no name
      [Fieldset.Name]: productData.name,
      [Fieldset.Environment]: getDefaultLightPreset(allEnvironments),
      [Fieldset.TextureSize]: 1024,
      [Fieldset.ChildrenConfig]: productData.materials?.map((item) => {
        const matchedConfig = productData.childrenConfig.find((configItem) => configItem.group?.id === item.group?.id) ?? {};

        return {
          ...item,
          productMaterial: item.material,
          group: item.group ? item.group : {
            id: undefined,
            name: 'Non-configurable Part',
          },
          material: undefined,
          preset: undefined,
          ...matchedConfig,
        };
      }) ?? [],
    },
  });

  const handleSubmit = async (formData) => {
    let isFormValid = true;
    const { [Fieldset.ChildrenConfig]: childrenConfig } = formData;

    childrenConfig.forEach(({ material, preset }, index) => {
      if (!material?.id && !preset?.id) {
        form.setError(`${Fieldset.ChildrenConfig}[${index}].fields`, {
          type: 'error',
          message: 'Please fill up at least one field of this group.',
        });

        isFormValid = false;
      }
    });

    if (isFormValid) {
      const preparedData = { childrenConfig: childrenConfig.map((item) => ({
        group: item.group?.id,
        material: item.material?.id,
        preset: item.preset?.id,
      })) };

      onSubmit(formData, false);

      dispatch(createVariantsConfig(productData.id, preparedData, form.setError));
    }
  };

  useEffect(() => {
    if (apiState === 'success') {
      onSubmit(null, true);
    }
  }, [dispatch, apiState, onSubmit]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default VariantsConfigurationForm;
