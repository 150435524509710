import {
  Typography,
  TextField,
  Grid,
  makeStyles,
  FormControl, Container,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/users/reducers';
import { actions as appActions } from '@store/app';
import Button from '@shared/components/button/Button';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(() => ({
  textField: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: '#334D6E',
  },
  button: {
    width: '140px',
    height: '42px',
    padding: '11px 0',
    gap: '10px',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '19.5px',
    letterSpacing: '1%',
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#00C98F',
    borderColor: '#00C98F',
    boxShadow: '0px 0px 0px 0px',
  },
  succesMessage: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: '#00C98F',
    paddingBottom: '10px',
  },
  headingField: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '27px',
    letterSpacing: '0.01em',
    textAlign: 'left',
    marginBottom: '20px',
  },
  root: {
    display: 'flex',
    width: '922px',
    height: '642px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarContainer: {
    width: '58px',
    height: '58px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0px',
  },
  container: {
    width: '100%',
    maxWidth: '820px',
  },
}));

const Profile = () => {
  const [nameChanged, setNameChanged] = useState(false);
  const { updateUser } = useActions(actions);
  const curUser = useSelector((state) => state.app.user);
  const dispatch = useDispatch();
  const { control: controlDetails, handleSubmit: handleSubmitDetails } = useForm();
  const { control: controlEmail, handleSubmit: handleSubmitEmail } = useForm();
  const { control: controlPassword, handleSubmit: handleSubmitPassword } = useForm();

  const classes = useStyles();
  const { setUser } = appActions;
  const createInitials = () => {
    if (curUser && curUser.firstname && curUser.lastname) {
      const initials = `${curUser.firstname.charAt(0)}${curUser.lastname.charAt(0)}`;
      return initials.toUpperCase();
    }
    return 'N/A';
  };

  const onSubmitDetail = async (data) => {
    updateUser({ id: curUser.id, user: data });
    const clonedUser = { ...curUser, ...data };
    dispatch(setUser(clonedUser));
    setNameChanged(true);
    setTimeout(() => {
      setNameChanged(false);
    }, 3000);
  };

  return (
    <Container className={ classes.root }>
      <Grid container className={ classes.container } spacing={ 8 }>
        <Grid item xs={ 12 } sm={ 12 } className={ classes.avatarContainer }>
          <Avatar aria-label="recipe" className="profile__icon_source">
            { createInitials() }
          </Avatar>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <form onSubmit={ handleSubmitDetails(onSubmitDetail) } noValidate>
            <Typography className={ classes.headingField } style={ { marginBottom: '20px' } }>My Details</Typography>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="firstname"
                render={ ({
                  field,
                  fieldState: { error },
                }) => (
                  <TextField
                    { ...field }
                    id="firstname"
                    type="text"
                    helperText={ error?.message ?? null }
                    variant="outlined"
                    label="First name"
                    required
                    error={ !!error }
                    style={ { marginBottom: '16px' } }
                    InputProps={ {
                      className: classes.textField,
                    } }
                    InputLabelProps={ {
                      className: classes.textField,
                    } }
                    FormHelperTextProps={ {
                      className: classes.textField,
                    } }
                  />
                ) }
                control={ controlDetails }
                defaultValue={ curUser?.firstname }
                rules={ { required: 'Required' } }
              />
              <Controller
                name="lastname"
                render={ ({
                  field,
                  fieldState: { error },
                }) => (
                  <TextField
                    { ...field }
                    id="lastname"
                    type="text"
                    helperText={ error?.message ?? null }
                    variant="outlined"
                    label="Last name"
                    required
                    error={ !!error }
                    style={ { marginBottom: '8px' } }
                    InputProps={ {
                      className: classes.textField,
                    } }
                    InputLabelProps={ {
                      className: classes.textField,
                    } }
                    FormHelperTextProps={ {
                      className: classes.textField,
                    } }
                  />
                ) }
                control={ controlDetails }
                defaultValue={ curUser?.lastname }
                rules={ { required: 'Required' } }
              />
            </FormControl>
            {nameChanged && (
              <Typography className={ classes.succesMessage }>
                Details changed successfully!
              </Typography>
            )}
            <Button type="submit" className={ classes.button }>
              Update Details
            </Button>
          </form>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <Typography className={ classes.headingField } style={ { marginBottom: '20px' } }>Password</Typography>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="currentpassword"
              render={ ({ field, fieldState: { error } }) => (
                <TextField
                  { ...field }
                  id="currentpassword"
                  type="password"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Current Password"
                  required
                  error={ !!error }
                  style={ { marginBottom: '16px' } }
                  InputProps={ {
                    className: classes.textField,
                  } }
                  InputLabelProps={ {
                    className: classes.textField,
                  } }
                  FormHelperTextProps={ {
                    className: classes.textField,
                  } }
                />
              ) }
              control={ controlPassword }
              defaultValue=""
              rules={ { required: 'Required' } }
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="newpassword"
              render={ ({ field, fieldState: { error } }) => (
                <TextField
                  { ...field }
                  id="newpassword"
                  type="password"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="New Password"
                  required
                  error={ !!error }
                  style={ { marginBottom: '8px' } }
                  InputProps={ {
                    className: classes.textField,
                  } }
                  InputLabelProps={ {
                    className: classes.textField,
                  } }
                  FormHelperTextProps={ {
                    className: classes.textField,
                  } }
                />
              ) }
              control={ controlPassword }
              defaultValue=""
              rules={ { required: 'Required' } }
            />
          </FormControl>
          <Button className={ classes.button } color="primary">
            Update password
          </Button>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 } style={ { marginTop: '-40px' } }>
          <Typography className={ classes.headingField } style={ { marginBottom: '20px' } }>Email</Typography>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="email"
              render={ ({ field, fieldState: { error } }) => (
                <TextField
                  { ...field }
                  id="email"
                  type="email"
                  helperText={ error?.message ?? null }
                  variant="outlined"
                  label="Email"
                  required
                  error={ !!error }
                  style={ { marginBottom: '16px' } }
                  InputProps={ {
                    className: classes.textField,
                  } }
                  InputLabelProps={ {
                    className: classes.textField,
                  } }
                  FormHelperTextProps={ {
                    className: classes.textField,
                  } }
                />
              ) }
              control={ controlEmail }
              defaultValue={ curUser?.email }
              rules={ { required: 'Email is required', pattern: /^\S+@\S+$/i } }
            />
          </FormControl>
          <Button className={ classes.button }>
            Update Email
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Profile;
