export const LOAD_ATTRIBUTE_DEFINITIONS = {
  START: 'LOAD_ATTRIBUTE_DEFINITIONS_START',
  SUCCESS: 'LOAD_ATTRIBUTE_DEFINITIONS_SUCCESS',
  FAIL: 'LOAD_ATTRIBUTE_DEFINITIONS_FAIL',
};

export const CLEAR_ATTRIBUTE_DEFINITIONS = 'CLEAR_ATTRIBUTE_DEFINITIONS';

export const CREATE_ATTRIBUTE_DEFINITION = 'CREATE_ATTRIBUTE_DEFINITION';

export const CREATE_ATTRIBUTE_DEFINITION_ERROR = 'CREATE_ATTRIBUTE_DEFINITION_ERROR';

export const CREATE_ATTRIBUTE_DEFINITION_SUCCESS = 'CREATE_ATTRIBUTE_DEFINITION_SUCCESS';

export const EDIT_ATTRIBUTE_DEFINITION = 'EDIT_ATTRIBUTE_DEFINITION';

export const EDIT_ATTRIBUTE_DEFINITION_ERROR = 'EDIT_ATTRIBUTE_DEFINITION_ERROR';

export const EDIT_ATTRIBUTE_DEFINITION_SUCCESS = 'EDIT_ATTRIBUTE_DEFINITION_SUCCESS';

export const RESET_ATTRIBUTE_API_STATE = 'RESET_ATTRIBUTE_API_STATE';

export const DELETE_ATTRIBUTE_DEFINITION = 'DELETE_ATTRIBUTE_DEFINITION';

export const ATTRIBUTES_FILTER = {
  SHOW: 'ATTRIBUTES_FILTER_SHOW',
  HIDE: 'ATTRIBUTES_FILTER_HIDE',
  TOGGLE: 'ATTRIBUTES_FILTER_TOGGLE',
  SET_VALUE: 'ATTRIBUTES_FILTER_SET_VALUE',
};

export const loadAttributeDefinitions = (entityTypes) => ({
  type: LOAD_ATTRIBUTE_DEFINITIONS.START,
  entityTypes,
});

export const loadAttributeDefinitionsSuccess = (attributeDefinitions) => ({
  type: LOAD_ATTRIBUTE_DEFINITIONS.SUCCESS,
  payload: {
    attributeDefinitions,
  },
});

export const loadAttributeDefinitionsFail = (error) => ({
  type: LOAD_ATTRIBUTE_DEFINITIONS.FAIL,
  payload: {
    error,
  },
});

export const clearAttributeDefinitions = () => ({
  type: CLEAR_ATTRIBUTE_DEFINITIONS,
});

export function createAttributeDefinition(attributeDefinition) {
  return {
    type: CREATE_ATTRIBUTE_DEFINITION,
    attributeDefinition,
  };
}

export function createAttributeDefinitionError(error) {
  return {
    type: CREATE_ATTRIBUTE_DEFINITION_ERROR,
    error,
  };
}

export function createAttributeDefinitionSuccess() {
  return {
    type: CREATE_ATTRIBUTE_DEFINITION_SUCCESS,
  };
}

export function editAttributeDefinition(attributeDefinition) {
  return {
    type: EDIT_ATTRIBUTE_DEFINITION,
    attributeDefinition,
  };
}

export function editAttributeDefinitionError(error) {
  return {
    type: EDIT_ATTRIBUTE_DEFINITION_ERROR,
    error,
  };
}

export function editAttributeDefinitionSuccess() {
  return {
    type: EDIT_ATTRIBUTE_DEFINITION_SUCCESS,
  };
}

export function deleteAttributeDefinition(id) {
  return {
    type: DELETE_ATTRIBUTE_DEFINITION,
    id,
  };
}

export function resetAttributeApiState() {
  return {
    type: RESET_ATTRIBUTE_API_STATE,
  };
}

export const showAttributesFilter = () => ({
  type: ATTRIBUTES_FILTER.SHOW,
});

export const hideAttributesFilter = () => ({
  type: ATTRIBUTES_FILTER.HIDE,
});

export const toggleAttributesFilter = () => ({
  type: ATTRIBUTES_FILTER.TOGGLE,
});

export const setAttributesFilterValue = (filterValue) => ({
  type: ATTRIBUTES_FILTER.SET_VALUE,
  payload: {
    filterValue,
  },
});
