import MuiSwitch from '@material-ui/core/Switch';

const Switch = ({
  name,
  checked,
  inputProps,
  onChange,
}) => (
  <MuiSwitch
    name={ name }
    checked={ checked }
    inputProps={ inputProps }
    onChange={ onChange }
  />
);

export default Switch;
