import { createSlice } from '@reduxjs/toolkit';
import { SortId } from '@app/sort/sortValues';

import {
  loadUsers,
  createUser,
  createUserError,
  createUserSuccess,
  updateUser,
  updateUserError,
  updateUserSuccess,
  deleteUser,
  resetUserState,
} from './actions';

const initialState = {
  users: [],
  sorting: {
    id: SortId.AtoZ,
    label: 'A-Z',
  },
  error: null,
  createSuccess: false,
  updateSuccess: false,
};


const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    renderUsers: (state, action) => {
      state.users = action.payload;
    },
    createUserError: (state, action) => {
      state.error = action.payload;
      state.createSuccess = false;
    },
    createUserSuccess: (state) => {
      state.error = null;
      state.createSuccess = true;
    },
    updateUserError: (state, action) => {
      state.error = action.payload;
      state.updateSuccess = false;
    },
    updateUserSuccess: (state) => {
      state.error = null;
      state.updateSuccess = true;
    },
    resetUserState: (state) => {
      state.createSuccess = false;
      state.updateSuccess = false;
      state.error = null;
    },
  },
});

export const actions = {
  ...usersSlice.actions,
  loadUsers,
  createUser,
  createUserError,
  createUserSuccess,
  updateUser,
  updateUserError,
  updateUserSuccess,
  deleteUser,
  resetUserState,
};

export default usersSlice.reducer;
