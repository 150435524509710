import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import {attributeDefinitionsApi, materialPresetsApi} from '@api';
import {
  LOAD_MATERIAL_PRESET_LIST,
  LOAD_MATERIAL_ATTRIBUTE_DEFINITION_LIST,
  CREATE_MATERIAL_PRESET,
  UPDATE_MATERIAL_PRESET,
  DELETE_MATERIAL_PRESET,

  loadMaterialPresetList,
  renderMaterialPresetList,
  renderMaterialAttributeDefinitionList,
  createMaterialPresetSuccess,
  createMaterialPresetError,
  updateMaterialPresetSuccess,
  updateMaterialPresetError,
  deleteMaterialPresetError,
} from './actions';
import { actions as appActions } from '@store/app/index';
import {EntityTypes} from '@shared/constants';

export function* fetchMaterialPresetList() {
  try {
    const data = yield call(materialPresetsApi.getMaterialPresets);
    yield put(renderMaterialPresetList(data));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMaterialAttributeDefinitionList() {
  try {
    const { items } = yield call(attributeDefinitionsApi.getAttributeDefinitions, [EntityTypes.Material]);
    yield put(renderMaterialAttributeDefinitionList(items));
  } catch (error) {
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during available attribute definitions', type: 'error' }));
  }
}

export function* fetchPostMaterialPreset({ materialPreset, setError }) {
  try {
    const data = yield call(materialPresetsApi.createMaterialPreset, materialPreset);
    yield put(loadMaterialPresetList());
    yield put(createMaterialPresetSuccess(data));
  } catch (error) {
    const unexpectedErrorMessage = 'Unexpected error during creating the Preset';

    if (error.response?.data) {
      const apiError = error.response?.data;
      const titles = [];

      apiError.message.forEach((message) => {
        if (message.reason === 'unique') {
          setError(message.property, {});
          titles.push(`Property '${message.property}' should be unique`);
        }
      });

      if (titles.length) {
        yield put(appActions.addMessage({ message: titles.join('\n'), type: 'error' }));
      } else {
        yield put(appActions.addMessage({ message: unexpectedErrorMessage, type: 'error' }));
      }

      yield put(createMaterialPresetError(apiError));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: unexpectedErrorMessage, type: 'error' }));
  }
}

export function* fetchPutMaterialPreset({ materialPreset, setError }) {
  try {
    yield call(materialPresetsApi.editMaterialPreset, materialPreset);
    yield put(loadMaterialPresetList());
    yield put(updateMaterialPresetSuccess());
  } catch (error) {
    const unexpectedErrorMessage = 'Unexpected error during saving the Preset';

    if (error.response?.data) {
      const apiError = error.response?.data;
      const titles = [];

      apiError.message.forEach((message) => {
        if (message.reason === 'unique') {
          setError(message.property, {});

          titles.push(`Property '${message.property}' should be unique`);
        }
      });

      if (titles.length) {
        yield put(appActions.addMessage({ message: titles.join('\n'), type: 'error' }));
      } else {
        yield put(appActions.addMessage({ message: unexpectedErrorMessage, type: 'error' }));
      }

      yield put(updateMaterialPresetError(apiError));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: unexpectedErrorMessage, type: 'error' }));
  }
}

export function* fetchDeleteMaterialPreset({ id }) {
  try {
    yield call(materialPresetsApi.deleteMaterialPreset, id);
    yield put(loadMaterialPresetList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteMaterialPresetError(id, error.response?.data));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during removing the Preset', type: 'error' }));
  }
}

export default function* materialPresetsSaga() {
  yield all([
    takeEvery(LOAD_MATERIAL_PRESET_LIST, fetchMaterialPresetList),
    takeEvery(LOAD_MATERIAL_ATTRIBUTE_DEFINITION_LIST, fetchMaterialAttributeDefinitionList),
    takeEvery(CREATE_MATERIAL_PRESET, fetchPostMaterialPreset),
    takeEvery(UPDATE_MATERIAL_PRESET, fetchPutMaterialPreset),
    takeEvery(DELETE_MATERIAL_PRESET, fetchDeleteMaterialPreset),
  ]);
}
