import {
  RENDER_MATERIAL_PRESET_LIST,
  RENDER_MATERIAL_ATTRIBUTE_DEFINITION_LIST,
  CREATE_MATERIAL_PRESET,
  CREATE_MATERIAL_PRESET_SUCCESS,
  CREATE_MATERIAL_PRESET_ERROR,
  UPDATE_MATERIAL_PRESET,
  UPDATE_MATERIAL_PRESET_SUCCESS,
  UPDATE_MATERIAL_PRESET_ERROR,
  DELETE_MATERIAL_PRESET_ERROR,
  RESET_MATERIAL_PRESET_API_STATE,
  SET_FRESH_MATERIAL_PRESET,
} from './actions';

const initialState = {
  items: [],
  page: 1,
  state: 'initial',
  error: null,
  attributeDefinitions: [],
  freshMaterialPreset: {
    groupIndex: -1,
    data: null,
  },
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case RENDER_MATERIAL_PRESET_LIST: {
      return {
        ...state,
        ...action.materialPresets,
      };
    }
    case RENDER_MATERIAL_ATTRIBUTE_DEFINITION_LIST: {
      return {
        ...state,
        attributeDefinitions: action.attributeDefinitions,
      };
    }
    case CREATE_MATERIAL_PRESET:
    case UPDATE_MATERIAL_PRESET:
      return {
        ...state,
        state: 'processing',
      };
    case CREATE_MATERIAL_PRESET_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'CREATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case UPDATE_MATERIAL_PRESET_ERROR:
      if (action.error) {
        return {
          ...state,
          error: {
            data: action.error,
            type: 'UPDATE',
          },
          state: 'error',
        };
      }
      return {
        ...state,
        error: null,
        state: 'success',
      };
    case CREATE_MATERIAL_PRESET_SUCCESS:
      return {
        ...state,
        freshMaterialPreset: {
          ...state.freshMaterialPreset,
          data: action.data,
        },
        state: 'success',
      };
    case UPDATE_MATERIAL_PRESET_SUCCESS:
      return {
        ...state,
        state: 'success',
      };
    case RESET_MATERIAL_PRESET_API_STATE:
      return {
        ...state,
        state: 'initial',
        error: null,
      };
    case DELETE_MATERIAL_PRESET_ERROR:
      if (action.id && action.error) {
        return {
          ...state,
          error: {
            id: action.id,
            data: action.error,
            type: 'DELETE',
          },
        };
      }
      return {
        ...state,
        error: null,
      };
    case SET_FRESH_MATERIAL_PRESET:
      return {
        ...state,
        freshMaterialPreset: {
          groupIndex: action.data.groupIndex,
          data: action.data.data,
        },
      };
    default:
      return state;
  }
}
