import http from '@shared/services/http';

import AuthApi from './auth';
import CompaniesApi from './companies';
import InteriorsApi from './interiors';
import ModelsApi from './models';
import ScenesApi from './scenes';
import UsersApi from './users';
import MaterialsApi from './materials';
import ProductsApi from './products';
import StudiosApi from './studios';
import AttributeDefinitionsApi from './attributeDefinitions';
import StylingApi from './styling';
import MaterialGroupsApi from './materialGroups';
import StatsApi from './stats';
import MaterialPresetsApi from './materialPresets';

export const authApi = new AuthApi(http);
export const statsApi = new StatsApi(http);
export const companiesApi = new CompaniesApi(http);
export const interiorsApi = new InteriorsApi(http);
export const modelsApi = new ModelsApi(http);
export const scenesApi = new ScenesApi(http);
export const usersApi = new UsersApi(http);
export const materialsApi = new MaterialsApi(http);
export const productsApi = new ProductsApi(http);
export const studiosApi = new StudiosApi(http);
export const attributeDefinitionsApi = new AttributeDefinitionsApi(http);
export const stylingApi = new StylingApi(http);
export const materialGroupsApi = new MaterialGroupsApi(http);
export const materialPresetsApi = new MaterialPresetsApi(http);
