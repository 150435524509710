import { all, call, put, takeEvery } from 'redux-saga/effects';

import { usersApi } from '@api';

import { actions } from './reducers';
import { actions as appActions } from '@store/app/index';

const { loadUsers,
  createUser,
  createUserError,
  createUserSuccess,
  updateUser,
  updateUserError,
  updateUserSuccess,
  deleteUser,
  renderUsers,
  resetUserState } = actions;

function* loadUsersSaga() {
  try {
    const { items } = yield call(usersApi.getUsers);
    yield put(renderUsers(items));
  } catch (e) {
    console.error(e);
  }
}

function* createUserSaga(action) {
  try {
    yield put(resetUserState());
    yield call(usersApi.createUser, action.payload);
    yield put(createUserSuccess());
    yield put(loadUsers());
  } catch (e) {
    console.error(e);
    yield put(createUserError(e.message || 'Property email should be unique'));
    yield put(appActions.addMessage({ message: 'Property email should be unique', type: 'error' }));
  }
}

function* updateUserSaga(action) {
  try {
    const { id, user } = action.payload;
    yield put(resetUserState());
    yield call(usersApi.updateUser, id, user);
    yield put(updateUserSuccess());
    yield put(loadUsers());
  } catch (e) {
    console.error(e);
    yield put(updateUserError(e.message || 'Property email should be unique'));
    yield put(appActions.addMessage({ message: 'Property email should be unique', type: 'error' }));
  }
}

function* deleteUserSaga(action) {
  try {
    yield call(usersApi.deleteUser, action.payload);
    yield put(loadUsers());
  } catch (e) {
    console.error(e);
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(loadUsers, loadUsersSaga),
    takeEvery(createUser, createUserSaga),
    takeEvery(updateUser, updateUserSaga),
    takeEvery(deleteUser, deleteUserSaga),
  ]);
}
