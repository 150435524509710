import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions as sideMenuActions } from '@store/app/index';
import { useSelector } from 'react-redux';
import { actions } from '@store/companies/reducers';
import { useEffect } from 'react';
import { isAdmin, isCollaborator } from '@shared/models';
import Home from '@pages/home';
import RelightSettings from '@pages/relight-settings';

const Relight = withAuth(() => {
  const curUser = useSelector((state) => state.app.user);
  const { loadCompanies } = useActions(actions);
  const { showMenu } = useActions(sideMenuActions);

  useEffect(() => {
    showMenu();
    if (isAdmin(curUser)) {
      loadCompanies();
    }
  }, [loadCompanies, showMenu, curUser]);

  if (isCollaborator(curUser)) {
    return <Home />;
  }

  return <RelightSettings />;
});

export default Relight;

