import { useCallback, useState } from 'react';
import EditModelForm from './ModelForm';
import FormFieldset from '../FormFieldset';
import ProductFormDialog from '../ProductFormDialog';
import CreateVariantFormDialog from '@pages/products/modal/create-variant/VariantFormDialog';
import EditVariantFormDialog from '@pages/products/modal/edit-variant/VariantFormDialog';
import CreateVariantsConfigurationFormDialog from '@pages/products/modal/create-variants-configuration/VariantsConfigurationFormDialog';
import CreateMaterialPresetFormDialog from '@pages/products/modal/create-material-preset/MaterialPresetFormDialog';
import EditMaterialPresetFormDialog from '@pages/products/modal/edit-material-preset/MaterialPresetFormDialog';
import { actions as appActions } from '@store/app';
import { useDispatch } from 'react-redux';
import { resetProductApiState } from '@store/products/actions';

const FormDialogTypes = {
  none: 'none',
  create: 'create',
  edit: 'edit',
};

const ModelFormDialog = ({
  open,
  data,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [variantData, setVariantData] = useState(null);
  const [materialPresetData, setMaterialPresetData] = useState(null);
  const [showVariantDialog, setShowVariantDialog] = useState(FormDialogTypes.none);
  const [showVariantsConfigurationDialog, setShowVariantsConfigurationDialog] = useState(FormDialogTypes.none);
  const [showMaterialPresetDialog, setShowMaterialPresetDialog] = useState(FormDialogTypes.none);
  const [initialTab, setInitialTab] = useState(0);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const handleSubmit = async (formData) => {
    dispatch(resetProductApiState());
  };

  const handleCancel = () => {
    dispatch(resetProductApiState());
    onClose();
    setInitialTab(0);
  };

  const checkVariantRequiredData = useCallback(() => {
    const allMaterialsSet = !data.materials.some((m) => !m.material);

    if (!allMaterialsSet) {
      dispatch(appActions.addMessage({ message: 'Please select all materials for the product to be able to create variations.', type: 'error' }));
      return false;
    }

    return true;
  }, [data, dispatch]);

  const handleAddVariantClick = useCallback(() => {
    if (checkVariantRequiredData()) {
      setVariantData(data ? ({
        ...data,
        parentProduct: { ...data },
        childProducts: [],
        id: null,
        attributes: [],
      }) : null);
      setShowVariantDialog(FormDialogTypes.create);
    }
  }, [data, checkVariantRequiredData]);

  const handleEditVariantClick = useCallback((variantId) => {
    const variant = data?.childProducts?.find((item) => item.id === variantId);

    setVariantData(variant ? ({ ...variant, parentProduct: { ...data } }) : null);
    setShowVariantDialog(FormDialogTypes.edit);
  }, [data]);

  const handleVariantModalClose = useCallback(() => {
    setShowVariantDialog(FormDialogTypes.none);
  }, []);

  const handleConfigureVariants = useCallback(() => {
    if (checkVariantRequiredData()) {
      setShowVariantsConfigurationDialog(FormDialogTypes.create);
    }
  }, [checkVariantRequiredData]);

  const handleVariantsConfigurationModalClose = useCallback(() => {
    setShowVariantsConfigurationDialog(FormDialogTypes.none);
  }, []);

  const handleAddMaterialPreset = useCallback(() => {
    setShowMaterialPresetDialog(FormDialogTypes.create);
  }, []);

  const handleEditMaterialPreset = useCallback((preset) => {
    setMaterialPresetData(preset);
    setShowMaterialPresetDialog(FormDialogTypes.edit);
  }, []);

  const handleMaterialPresetModalClose = useCallback(() => {
    setShowMaterialPresetDialog(FormDialogTypes.none);
  }, []);

  // TODO: check FormFieldset props. ATM it is only "renewable" is available
  return (
    <>
      <ProductFormDialog
        open={ open }
        Form={ EditModelForm }
        FormProps={ {
          open,
          data,
          onSubmit: handleSubmit,
        } }
        title={ data?.id ? 'Edit Product' : 'Add New Product' }
        isSaveDisabled={ isSaveDisabled }
        content={ (
          <FormFieldset
            data={ data }
            onAddVariantClick={ handleAddVariantClick }
            onEditVariantClick={ handleEditVariantClick }
            onConfigureVariants={ handleConfigureVariants }
            initialtab={ initialTab }
            onTabChange={ setInitialTab }
            onObject={ (object) => {
              setIsSaveDisabled(!object);
            } }
          />
        ) }
        onCancel={ handleCancel }
        onClose={ handleCancel }
        cancelButtonText="Quit"
      />

      <CreateVariantFormDialog
        open={ showVariantDialog === FormDialogTypes.create }
        data={ variantData }
        onClose={ handleVariantModalClose }
      />

      <EditVariantFormDialog
        open={ showVariantDialog === FormDialogTypes.edit }
        data={ variantData }
        onClose={ handleVariantModalClose }
      />

      <CreateVariantsConfigurationFormDialog
        open={ showVariantsConfigurationDialog === FormDialogTypes.create }
        productData={ data }
        onAddMaterialPreset={ handleAddMaterialPreset }
        onEditMaterialPreset={ handleEditMaterialPreset }
        onClose={ handleVariantsConfigurationModalClose }
      />

      <CreateMaterialPresetFormDialog
        open={ showMaterialPresetDialog === FormDialogTypes.create }
        onClose={ handleMaterialPresetModalClose }
      />

      <EditMaterialPresetFormDialog
        open={ showMaterialPresetDialog === FormDialogTypes.edit }
        data={ materialPresetData }
        onClose={ handleMaterialPresetModalClose }
      />
    </>
  );
};

export default ModelFormDialog;
