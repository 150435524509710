import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    width: '156px',
    height: '31px',
    position: 'relative',
    justifyContent: 'space-between',
    display: 'flex',
    userSelect: 'none',
    flexDirection: 'row',
    border: 'none',
    borderRadius: '5px',
    overflow: 'hidden',
    background: '#f1f1f1',
    cursor: 'pointer',
  },
  select: {
    width: '100%',
    height: '100%',
    border: 'none',
    borderRadius: '5px',
    padding: '0 8px',
    fontSize: '14px',
    color: '#334D6E',
    //appearance: 'none',
    outline: 'none',
    background: 'transparent',
  },
  option: {
    border: '1px solid #334D6E',
    borderRadius: '5px',
  },
});

const Dropdown = ({ companies, onSelectCompany, curCompany }) => {
  const classes = useStyles();
  const [selectedCompanyInside, setSelectedCompanyInside] = useState(curCompany);
  const handleChange = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompanyObj = companies.find((company) => company.id === selectedCompanyId);
    onSelectCompany(selectedCompanyObj);
    setSelectedCompanyInside(event.target.value);
  };

  return (
    <div className={ classes.root }>
      <select
        className={ classes.select }
        value={ selectedCompanyInside }
        onChange={ handleChange }
      >
        {companies
          .filter((company) => !company.isDeleted)
          .map((company) => (
            <option className={ classes.option } key={ company.id } value={ company.id }>
              {company.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Dropdown;
