import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies/reducers';
import { actions as sideMenuActions } from '@store/app/index';

import CompaniesList from '@pages/companies/list';
import { selectCurrentCompany } from '@store/app/selectors';
import { isAdmin } from '@shared/models';

const Companies = withAuth(() => {
  const curUser = useSelector((state) => state.app.user);

  const { loadCompanies } = useActions(actions);
  const { showMenu } = useActions(sideMenuActions);
  const company = useSelector(selectCurrentCompany);

  useEffect(() => {
    showMenu();
    window.scrollTo(0, 0);

    if (isAdmin(curUser)) loadCompanies();
  }, [loadCompanies, isAdmin, company, curUser]);

  return (
    <CompaniesList />
  );
});

export default Companies;
