import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Button from '@shared/components/button';
import PlusIcon from '@shared/icons/PlusIcon';
import EditIcon from '@resources/icons/EditIcon';
import { loadStyling } from '@store/styling/actions';
import Breadcrumbs from '@shared/components/breadcrumbs';
import StylingModal from '@pages/styling/modal';
import { selectStyling } from '@store/styling/selectors';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.2rem',
    },
  },
  colorCell: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    border: '1px dashed gray',
    display: 'inline-block',
    marginRight: '8px',
  },
}));

const BREADCRUMBS_ITEMS = [{ name: 'Styling' }];

const Styling = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const stylingItems = useSelector(selectStyling);

  useEffect(() => {
    dispatch(loadStyling());
  }, [dispatch]);

  const handleCreateClick = useCallback(() => {
    setModalOpen(true);
    setModalData(null);
  }, []);

  const handleEditClick = useCallback((data) => {
    const { color1, color1Hover, color2, color2Hover, color3, color3Hover, color4, color4Hover, fontFamily } = data;
    setModalData({ color1, color1Hover, color2, color2Hover, color3, color3Hover, color4, color4Hover, fontFamily });
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const renderColorCell = useCallback((color) => (
    <div
      style={ {
        backgroundColor: color,
      } }
      className={ classes.colorCell }
    />
  ), [classes]);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />

          {!stylingItems?.length ? (
            <Button
              leftPlace={ <PlusIcon /> }
              onClick={ handleCreateClick }
            >
              Create new
            </Button>
          ) : null}
        </TopNav>

        <StylingModal
          open={ modalOpen }
          data={ modalData }
          onClose={ handleModalClose }
        />
      </PageHead>
      <PageContent>
        <TableContainer component={ Paper }>
          <Table className={ classes.table }>
            <TableHead>
              <TableRow>
                <TableCell>Company ID</TableCell>
                <TableCell>Base Colors</TableCell>
                <TableCell>Font</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stylingItems?.map((item) => (
                <TableRow key={ item.id }>
                  <TableCell>{ item.company }</TableCell>
                  <TableCell>
                    { renderColorCell(item.color1) }
                    { renderColorCell(item.color2) }
                    { renderColorCell(item.color3) }
                    { renderColorCell(item.color4) }
                  </TableCell>
                  <TableCell>
                    {
                    ['Montserrat', 'Open Sans', 'Roboto'].includes(item.fontFamily)
                      ? `${item.fontFamily} (Cyrillic supported)`
                      : item.fontFamily
                      }
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={ handleEditClick.bind(this, item) }>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </PageContainer>
  );
};

export default Styling;
