import { useMemo } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.2rem',
    },
    '& .MuiTableCell-root[disabled]': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  chipsStack: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const MAX_CHIPS_SIZE = 7;

const AttributeDefinitionsCustomList = ({ attributeDefinitions = [] }) => {
  const classes = useStyles();

  const flatAttributes = useMemo(() => {
    if (attributeDefinitions) {
      const flatMap = {};
      for (const ad of attributeDefinitions) {
        flatMap[ad.id] = ad.attributes.map((attribute) => attribute.value);
      }
      return flatMap;
    }

    return {};
  }, [attributeDefinitions]);

  const CustomTooltip = withStyles(() => ({
    tooltip: {
      //make changes if needed
      fontSize: 11,
    },
  }))(Tooltip);

  return attributeDefinitions.length ? (
    <TableContainer component={ Paper }>
      <Table className={ classes.table }>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Values</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attributeDefinitions.map((attrDef) => {
            return (
              <TableRow key={ attrDef.id }>
                <TableCell>{attrDef.name}</TableCell>
                <CustomTooltip title={ flatAttributes[attrDef.id].join(',') } placement="bottom-start">
                  <TableCell>
                    <div className={ classes.chipsStack }>
                      {flatAttributes[attrDef.id].slice(0, MAX_CHIPS_SIZE).map((value) => (
                        <Chip size="small" label={ value } key={ value } />
                      ))}
                      {flatAttributes[attrDef.id].length > MAX_CHIPS_SIZE ? (
                        <Chip size="small" label={ `+ ${flatAttributes[attrDef.id].length - MAX_CHIPS_SIZE}` } />
                      ) : null}
                    </div>
                  </TableCell>
                </CustomTooltip>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default AttributeDefinitionsCustomList;
