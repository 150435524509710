import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormControl, Grid, makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ReactComponent as Logo } from '@images/icons/adminLogo.svg';

import Button from '@shared/components/button';
import useActions from '@shared/hooks/useActions';
import useNavigate from '@shared/router/useNavigate';
import { actions } from '@store/app';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '400px',
  },
}));

const Login = () => {
  const user = useSelector((s) => s.app.user);
  const errorMessage = useSelector((s) => s.app.error);
  const { login, clearError } = useActions(actions);

  const location = useLocation();
  const { goTo } = useNavigate();

  const { control, formState: { errors: fieldsErrors }, handleSubmit } = useForm();
  const classes = useStyles();

  useEffect(() => {
    if (user) goTo(location.state?.from || '/', { replace: true });
  }, [user, goTo, location]);

  useEffect(() => clearError, [clearError]);

  const onSubmit = (credentials) => {
    clearError();
    login(credentials);
  };

  return (
    <form className={ classes.root } onSubmit={ handleSubmit(onSubmit) } noValidate>
      <Grid container className={ classes.container } spacing={ 2 }>
        <Grid item xs={ 12 }>
          <Logo style={ { margin: '0px 35px 70px 35px' } } />
          <FormControl fullWidth variant="outlined">
            <Controller
              name="email"
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  id="email"
                  helperText={ fieldsErrors.email?.message ?? null }
                  variant="outlined"
                  label="Email"
                  required
                  error={ !!fieldsErrors.email }
                />
              ) }
              control={ control }
              defaultValue=""
              rules={ {
                required: 'Required',
              } }
            />
          </FormControl>
        </Grid>
        <Grid item xs={ 12 }>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="password"
              render={ ({ field }) => (
                <TextField
                  { ...field }
                  id="password"
                  type="password"
                  helperText={ fieldsErrors.password?.message ?? null }
                  variant="outlined"
                  label="Password"
                  required
                  error={ !!fieldsErrors.password }
                />
              ) }
              control={ control }
              defaultValue=""
              rules={ {
                required: 'Required',
              } }
            />
          </FormControl>
        </Grid>
        <Grid item xs={ 12 }>
          { errorMessage && <Alert severity="error">{ errorMessage }</Alert> }
        </Grid>
        <Grid item container justifyContent="center">
          <Button type="submit">Login</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Login;
