import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useController, useFormContext, useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTextField from './MaterialTextField';
import {
  Fields,
  Field,
} from '@shared/views/form-dialog';
import { getWireframeMaterial } from '@pages/products/utils';
import MaterialPresetTextField from '@pages/products/modal/MaterialPresetTextField';
import { useDispatch, useSelector } from 'react-redux';
import { selectFreshMaterialPreset, selectMaterialPresets } from '@store/material-presets/selectors';
import classNames from 'classnames';
import { setFreshMaterialPreset } from '@store/material-presets/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0 8px',
  },
  groupWrapper: {
    padding: '20px 0',
  },
  groupLabel: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.common.darkGrey,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.green,
    },
  },
  errorLabel: {
    color: theme.palette.common.red,

    '&:hover': {
      color: theme.palette.common.red,
    },
  },
  errorMessage: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.common.red,
  },
  pairFieldsSeparationLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
  },
}));

const VariantsConfigurationMaterialTable = ({
  name,
  control,
  onAddMaterialPreset,
  onEditMaterialPreset,
  object,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const materialPresets = useSelector(selectMaterialPresets);
  const freshPreset = useSelector(selectFreshMaterialPreset);

  const {
    field: { value },
  } = useController({
    name,
    control,
  });

  const form = useFormContext();

  const {
    setValue,
    formState: { errors: fieldsErrors },
    clearErrors,
  } = form;

  const currentValue = useWatch({ control, name });

  useEffect(() => {
    fieldsErrors[name]?.forEach((error, index) => {
      if (fieldsErrors[name][index].fields && (currentValue[index].material?.id || currentValue[index].preset?.id)) {
        clearErrors(`${name}[${index}].fields`);
      }
    });
  }, [currentValue, fieldsErrors, clearErrors, name]);

  useEffect(() => {
    if (freshPreset?.data && freshPreset?.groupIndex >= 0 && !currentValue?.[freshPreset.groupIndex]?.material?.id) {
      dispatch(setFreshMaterialPreset({ groupIndex: -1, data: null }));
      setValue(`${name}[${freshPreset.groupIndex}].preset`, freshPreset.data);
    }
  }, [freshPreset, currentValue, name, setValue, dispatch]);

  const presetOptions = useMemo(() => (materialPresets?.items ?? []), [materialPresets]);

  const meshes = useMemo(() => {
    const result = [];
    if (!object) {
      return result;
    }
    object.traverse((element) => {
      if (element.isMesh) {
        result.push(element);
      }
    });

    return result;
  }, [object]);

  const groups = useMemo(() => {
    return [...value.reduce((a, c) => {
      if (c.group.id) {
        a.set(c.group.id, c.group);
      } else {
        a.set(value.indexOf(c), c.group);
      }
      return a;
    }, new Map()).values()];
  }, [value]);

  const onClickHandler = useCallback((indexes) => {
    value.forEach((item, index) => {
      if (indexes.indexOf(index) > -1) {
        const wireframeMaterial = getWireframeMaterial();
        wireframeMaterial.wireframe = true;
        meshes[index].userData.wireframeToApply = wireframeMaterial;
      }
    });
  }, [value, meshes]);

  const mouseLeaveHandler = useCallback((indexes) => {
    value.forEach((item, index) => {
      if (indexes.indexOf(index) > -1 && meshes[index].userData.wireframeToApply) {
        meshes[index].userData.wireframeToApply.dispose();
        delete meshes[index].userData.wireframeToApply;
      }
    });
  }, [value, meshes]);

  const handleMaterialSelect = useCallback((indexes, material) => {
    const result = value.map((item, index) => {
      if (indexes.indexOf(index) > -1) {
        return {
          ...item,
          material,
        };
      }
      return item;
    });

    setValue(name, result);
  }, [name, value, setValue]);

  const handleAddMaterialPreset = useCallback((groupIndex) => {
    dispatch(setFreshMaterialPreset({ groupIndex, data: null }));
    onAddMaterialPreset();
  }, [dispatch, onAddMaterialPreset]);

  return (
    <div className={ classes.root }>
      {
        groups.map((group, index) => {
          const groupIndex = group.id
            ? value.findIndex((i) => i.group.id === group.id)
            : value.findIndex((i) => i.group === group);
          const indexes = group.id
            ? value.map((e, i) => (e.group.id === group.id ? i : ''))
              .filter(String)
            : [groupIndex];
          return (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={ index }
            >
              <div className={ classes.groupWrapper }>
                <div
                  className={ classNames(classes.groupLabel, { [classes.errorLabel]: !!fieldsErrors[name]?.[groupIndex]?.fields }) }
                  onClick={ onClickHandler.bind(this, indexes) }
                  onMouseLeave={ mouseLeaveHandler.bind(this, indexes) }
                >
                  {group.name}
                </div>

                {fieldsErrors[name]?.[groupIndex]?.fields ? (
                  <div className={ classes.errorMessage }>
                    &gt; {fieldsErrors[name][groupIndex].fields.message}
                  </div>
                ) : null}
              </div>

              <Fields>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name={ `${name}[${groupIndex}].material` }
                    control={ control }
                    defaultValue={ { name: '' } }
                    render={ ({ field }) => (
                      <MaterialTextField
                        { ...field }
                        label="Material"
                        disabled={ !!currentValue?.[groupIndex]?.preset?.id }
                        onSelect={ handleMaterialSelect.bind(this, indexes) }
                      />
                    ) }
                  />
                </Field>

                <div className={ classes.pairFieldsSeparationLabel }>
                  or
                </div>

                <Field style={ { position: 'relative' } }>
                  <Controller
                    name={ `${name}[${groupIndex}].preset` }
                    control={ control }
                    defaultValue={ { name: '' } }
                    render={ ({ field }) => (
                      <MaterialPresetTextField
                        { ...field }
                        label="Material Preset"
                        disabled={ !!currentValue?.[groupIndex]?.material?.id }
                        options={ presetOptions }
                        onAddMaterialPreset={ handleAddMaterialPreset.bind(null, groupIndex) }
                        onEditMaterialPreset={ onEditMaterialPreset }
                      />
                    ) }
                  />
                </Field>
              </Fields>
            </div>
          );
        })
      }
    </div>
  );
};

export default VariantsConfigurationMaterialTable;
