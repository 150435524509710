import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadAttributeDefinitions } from '@store/attribute-definitions/actions';

import {
  loadTexturesList,
  loadMaterialsList,
} from '@store/materials/actions';

import withAuth from '@shared/components/route/withAuth';

import MaterialList from '@pages/materials/list';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';
import { EntityTypes } from '@shared/constants';
import { loadEnvironmentsList } from '@store/scenes/actions';

export const MaterialsList = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(actions);

  useEffect(() => {
    showMenu();
    dispatch(loadTexturesList());
    dispatch(loadMaterialsList());
    dispatch(loadEnvironmentsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Material]));
    window.scrollTo(0, 0);
  }, [dispatch, showMenu]);

  return <MaterialList />;
});
