import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Button from '@shared/components/button';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import useAlertControl, {
  Types,
} from '@shared/components/card-controls/useAlertControl';
import PlusIcon from '@shared/icons/PlusIcon';
import EditIcon from '@resources/icons/EditIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/users/reducers';

import UserModal, { ActionType } from '../modal';
import { selectSortingId } from '@store/app/selectors';
import Breadcrumbs from '@shared/components/breadcrumbs';
import sortData from '@app/sort';
import { isAdmin, isCollaborator, isCompanyAdmin } from '@shared/models';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.2rem',
    },
  },
}));

const BREADCRUMBS_ITEMS = [{ name: 'Users' }];

const UsersList = () => {
  const curUser = useSelector((state) => state.app.user);
  const users = useSelector((state) => state.users.users);
  const companies = useSelector((state) => state.companies.companies.reduce((map, obj) => { map[obj.id] = obj.name; return map; }, {}));
  const sortingId = useSelector(selectSortingId);
  const { deleteUser } = useActions(actions);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);

  const classes = useStyles();

  const usersToDisplay = useMemo(() => sortData(users, sortingId, 'email').map((u) => (
    { ...u, companyName: companies[u.company] }
  )), [users, sortingId, companies]);

  const [
    alertOpen,
    { handleAlertControlClick, handleAgree, handleDisagree, ariaAction, title },
  ] = useAlertControl();

  const onUserCreate = () => {
    setModalOpen(true);
    setModalData(null);
    setModalType(ActionType.CREATE);
  };

  const onUserEdit = (user) => {
    setModalOpen(true);
    setModalType(ActionType.UPDATE);
    setModalData(user);
  };

  const onUserDelete = (user) => {
    handleAlertControlClick(Types.Delete, () => deleteUser(user.id))();
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
        <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          { isCollaborator(curUser) ? null : (
            <Button
              leftPlace={ <PlusIcon /> }
              onClick={ onUserCreate }
            >
              Create new
            </Button>
          ) }

        </TopNav>
        <UserModal
          open={ modalOpen }
          type={ modalType }
          data={ modalData }
          onClose={ onModalClose }
        />
        <AlertDialog
          open={ alertOpen }
          ariaAction={ ariaAction }
          title={ title }
          onAgree={ handleAgree }
          onDisagree={ handleDisagree }
        />
      </PageHead>
      <PageContent>
        <TableContainer component={ Paper }>
          <Table className={ classes.table }>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isCollaborator(curUser)
                ? (
                  <TableRow key={ curUser.id }>
                    <TableCell>{curUser.email}</TableCell>
                    <TableCell>{curUser.company.name}</TableCell>
                    <TableCell>{curUser.role}</TableCell>
                    <TableCell>{curUser.status}</TableCell>
                    <TableCell align="right">
                      <IconButton disabled>
                        <EditIcon />
                      </IconButton>
                      <IconButton disabled>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
                : (
                  usersToDisplay.map((u) => (
                    <TableRow key={ u.id }>
                      <TableCell>{u.email}</TableCell>
                      <TableCell> { isCompanyAdmin(curUser) ? (curUser.company.name) : (u.companyName) }</TableCell>
                      <TableCell>{u.role}</TableCell>
                      <TableCell>{u.status}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          disabled={ (isAdmin(u) && !isAdmin(curUser)) || u.id === curUser.id }
                          onClick={ () => onUserEdit(u) }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          disabled={ (isAdmin(u) && !isAdmin(curUser)) || u.id === curUser.id }
                          onClick={ () => onUserDelete(u) }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </PageContainer>
  );
};

export default UsersList;
