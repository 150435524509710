import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadAttributeDefinitions } from '@store/attribute-definitions/actions';

import withAuth from '@shared/components/route/withAuth';

import ModelList from '@pages/models/list';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';
import { loadModelsList } from '@store/models/actions';
import { EntityTypes } from '@shared/constants';
import { loadEnvironmentsList } from '@store/scenes/actions';

export const ModelsList = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(actions);

  useEffect(() => {
    showMenu();
    dispatch(loadModelsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Model]));
    dispatch(loadEnvironmentsList());
    window.scrollTo(0, 0);
  }, [dispatch, showMenu]);

  return <ModelList />;
});
