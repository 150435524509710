import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadProductsList } from '@store/products/actions';
import { loadModelsList } from '@store/models/actions';
import { loadMaterialsList } from '@store/materials/actions';
import { loadAttributeDefinitions } from '@store/attribute-definitions/actions';

import withAuth from '@shared/components/route/withAuth';

import ProductList from '@pages/products/list';

import { actions } from '@store/app';
import useActions from '@shared/hooks/useActions';
import { EntityTypes } from '@shared/constants';
import { loadEnvironmentsList } from '@store/scenes/actions';

const ProductsList = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(actions);

  useEffect(() => {
    showMenu();
    dispatch(loadProductsList());
    dispatch(loadModelsList());
    dispatch(loadMaterialsList());
    dispatch(loadEnvironmentsList());
    dispatch(loadAttributeDefinitions([EntityTypes.Product]));
    window.scrollTo(0, 0);
  }, [dispatch, showMenu]);

  return <ProductList />;
});

export default ProductsList;
