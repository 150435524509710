import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import withAuth from '@shared/components/route/withAuth';
import useActions from '@shared/hooks/useActions';
import { loadAttributeDefinitions } from '@store/attribute-definitions/actions';
import { actions as sideMenuActions } from '@store/app/index';

import AttributeDefinitionsList from '@pages/attribute-definitions/list';

const AttributeDefinitions = withAuth(() => {
  const dispatch = useDispatch();
  const { showMenu } = useActions(sideMenuActions);

  useEffect(() => {
    showMenu();
    dispatch(loadAttributeDefinitions());
    window.scrollTo(0, 0);
  }, [showMenu, dispatch]);

  return <AttributeDefinitionsList />;
});

export default AttributeDefinitions;
